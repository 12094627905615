import { useCallback, useEffect } from "react";
import {
  UlmMessage,
  getUlmUrl,
  isUlmMessage,
} from "@context-providers/auth/use-auth-ulm-utils";
import { runtimeConfig } from "@src/runtime-config";

const sphereEntryPageUrl = runtimeConfig.urls.sphereEntryPageUrl;

interface Props {
  /** Callback to execute when the user has selected the same server as this app */
  selectedServerCallback: (userEmail: string) => void;
}

interface UseAuthUserModule {
  /** ULM URL for the iFrame tha will embed it */
  ulmUrl: string;
}

/** Custom hook that handles an instance of the unified login mask */
export function useAuthUlm({
  selectedServerCallback,
}: Props): UseAuthUserModule {
  const ulmUrl = getUlmUrl();

  // Memoize callback to ensure it does not trigger unnecessary re-renders.
  const memoizedSelectedServerCallback = useCallback(
    (userEmail: string) => {
      selectedServerCallback(userEmail);
    },
    [selectedServerCallback]
  );

  // Add event listener for messages coming from the ULM
  useEffect(() => {
    function onWindowMessage(message: MessageEvent<UlmMessage>): void {
      // Early exit if the window message does not come from the ULM
      if (message.origin !== new URL(sphereEntryPageUrl).origin) {
        return;
      }

      if (isUlmMessage(message.data)) {
        memoizedSelectedServerCallback(message.data.userEmail);
      }
    }

    window.addEventListener("message", onWindowMessage);

    // Cleanup. Remove event listener before the component is unmounted or re-rendered.
    return () => window.removeEventListener("message", onWindowMessage);
  }, [memoizedSelectedServerCallback]);

  return { ulmUrl };
}
