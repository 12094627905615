import { getProjectApiClient } from "@api/project-api/project-api-utils";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { IElementType } from "@faro-lotv/ielement-types";
import { isCaptureTreeScanEntity } from "@pages/project-details/project-data-management/raw-scans/raw-scans-utils";
import {
  fetchAllRegistrationRevisions,
  fetchCaptureTreeForMainRevision,
} from "@store/capture-tree/capture-tree-thunks";
import { fetchProjectIElements } from "@store/i-elements/i-elements-slice";
import { useAppDispatch } from "@store/store-helper";
import { useEffect } from "react";

/** Delta time in milliseconds used to poll the capture tree data from the backend */
const POLLING_DELTA = 60000;

/**
 * Custom hook that polls the capture tree data
 */
export function useCaptureTree({
  projectId,
}: Partial<BaseProjectIdProps>): void {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!projectId) {
      return;
    }

    const projectApiClient = getProjectApiClient({ projectId });

    /**
     * Fetches:
     * - The registration revisions of the current project
     * - The capture tree entities for the main revision of the current project
     * - Additionally it also fetches the scan ielements and their children PointCloudStream ielement from the BI tree.
     * The presence of the PointCloudStream as children of the scan indicates that the scan has been processed.
     */
    async function fetchCaptureTreeData(): Promise<void> {
      dispatch(fetchAllRegistrationRevisions({ projectApiClient }));
      const entities = await dispatch(
        fetchCaptureTreeForMainRevision({ projectApiClient })
      ).unwrap();

      const scanEntities = entities.filter(isCaptureTreeScanEntity);
      const scanEntitiesIds = scanEntities.map((entity) => entity.id);

      dispatch(
        fetchProjectIElements({
          fetcher: async () => {
            const rootElementPromise = projectApiClient.getRootIElement();

            const scanElementsPromise = projectApiClient.getAllIElements({
              ids: scanEntitiesIds,
            });

            const pointCloudStreamElementsPromise =
              projectApiClient.getAllIElements({
                types: [IElementType.pointCloudStream],
                ancestorIds: scanEntitiesIds,
              });

            const [rootElement, scanElements, pointCloudStreamElements] =
              await Promise.all([
                rootElementPromise,
                scanElementsPromise,
                pointCloudStreamElementsPromise,
              ]);

            return [rootElement, ...scanElements, ...pointCloudStreamElements];
          },
        })
      );
    }

    // Fetch data once on page load
    fetchCaptureTreeData();

    // Poll data
    const pollingIntervalId = setInterval(fetchCaptureTreeData, POLLING_DELTA);

    return () => clearInterval(pollingIntervalId);
  }, [dispatch, projectId]);
}
