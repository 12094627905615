import { Box } from "@mui/material";
import { DateTimeUtils } from "@stellar/web-core";
import { useMemo } from "react";

interface Props {
  /** A generic data string */
  date: string;
}

/** Renders a date cell in the the point clouds table */
export function PointCloudDate({ date }: Props): JSX.Element {
  const dateLabel = useMemo(() => {
    return DateTimeUtils.format({
      date,
      format: DateTimeUtils.EAllowedDateFormat.generalWithSpace,
    });
  }, [date]);

  return <Box component={"var"}>{dateLabel}</Box>;
}
