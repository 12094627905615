import { MemberTypes } from "@custom-types/member-types";
import {
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  RawScan,
  RawScansTableHeaders,
} from "@pages/project-details/project-data-management/raw-scans/raw-scans-types";
import { FaroTableTextCell } from "@components/common/faro-table/faro-table-text-cell";
import { faroTableComparator } from "@components/common/faro-table/faro-table-utils";
import { getMemberNameById } from "@utils/member-utils";
import { FaroTableMemberCell } from "@components/common/faro-table/faro-table-member-cell";
import { Typography } from "@mui/material";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { convertToDateTimeString } from "@faro-lotv/foundation";
import { getDeviceType } from "@pages/project-details/project-data-management/raw-scans/raw-scans-utils";

interface Props {
  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

/**
 * Returns a an object with the raw scans table columns:
 * - Each property key is the column name
 * - Each property value is a GridColDef object of the MUI data grid
 */
export function getRawScansTableColumns({
  companyMembers,
  projectMembers,
}: Props): Record<RawScansTableHeaders, GridColDef> {
  return {
    [RawScansTableHeaders.name]: {
      field: RawScansTableHeaders.name,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: RawScan }>) => {
        const name = params.row.entity.name;
        return (
          <FaroTableTextCell
            text={name}
            sx={{
              fontWeight: "bold",
            }}
          />
        );
      },
      valueGetter: (_, row: { entity: RawScan }) => row.entity.name,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [RawScansTableHeaders.clusterPath]: {
      field: RawScansTableHeaders.clusterPath,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: RawScan }>) => {
        const clusterPath = params.row.entity.clusterPath;
        return <FaroTableTextCell text={clusterPath} />;
      },
      valueGetter: (_, row: { entity: RawScan }) => row.entity.clusterPath,
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [RawScansTableHeaders.createdBy]: {
      field: RawScansTableHeaders.createdBy,
      minWidth: 180,
      flex: 1,
      renderCell: (params: GridRenderCellParams<{ entity: RawScan }>) => {
        return (
          <FaroTableMemberCell
            memberId={params.row.entity.createdBy}
            companyMembers={companyMembers}
            projectMembers={projectMembers}
          />
        );
      },
      valueGetter: (_, row: { entity: RawScan }) =>
        getMemberNameById({
          memberId: row.entity.createdBy,
          companyMembers,
          projectMembers,
        }),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [RawScansTableHeaders.createdAt]: {
      field: RawScansTableHeaders.createdAt,
      minWidth: 160,
      flex: 0.75,
      type: "date",
      renderCell: (params: GridRenderCellParams<{ entity: RawScan }>) => {
        const date = convertToDateTimeString(params.row.entity.createdAt);
        return <FaroTableTextCell text={date} />;
      },
      valueGetter: (_, row: { entity: RawScan }) =>
        new Date(row.entity.createdAt),

      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<Date | undefined>(
          v1,
          v2,
          cp1,
          cp2,
          gridDateComparator
        ),
    },

    [RawScansTableHeaders.deviceType]: {
      field: RawScansTableHeaders.deviceType,
      minWidth: 100,
      flex: 0.5,
      renderCell: (params: GridRenderCellParams<{ entity: RawScan }>) => {
        const deviceType = getDeviceType(params.row.entity.type);
        return <FaroTableTextCell text={deviceType} />;
      },
      valueGetter: (_, row: { entity: RawScan }) =>
        getDeviceType(row.entity.type),
      // eslint-disable-next-line @typescript-eslint/naming-convention -- name given by package
      sortable: true,
      sortComparator: (v1, v2, cp1, cp2) =>
        faroTableComparator<string>(
          v1,
          v2,
          cp1,
          cp2,
          gridStringOrNumberComparator
        ),
    },

    [RawScansTableHeaders.actions]: {
      field: RawScansTableHeaders.actions,
      type: "actions",
      align: "right",
      minWidth: 120,
      flex: 0.75,
      renderCell: (params: GridRenderCellParams<{ entity: RawScan }>) => {
        return params.row.entity.isProcessing ? (
          <>
            <Typography
              sx={{
                color: sphereColors.blue500,
                fontSize: "12px",
                fontWeight: "600",
                ...withEllipsis,
              }}
            >
              Processing
            </Typography>
            <FaroButtonSpinner loadingTrackColor={sphereColors.gray200} />
          </>
        ) : null;
      },
    },
  };
}
