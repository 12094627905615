import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { filtersForSpecificColumnSelector } from "@store/table/table-selector";
import { updateFiltersInColumn } from "@store/table/table-slice";
import {
  FilterColumnNames,
  FilterOption,
} from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { useMemo } from "react";
import { FilterPayload } from "@store/table/table-slice-helper";

interface Props {
  /** The column in which filtering will apply to */
  filteredColumn: FilterColumnNames;
}

export interface UpdateItemFilterCallback {
  /** The new filter item that user selects */
  newFilterItem: FilterOption;

  /** Whether to add the new filter item to the list, remove it or replace it */
  shouldReplaceFilterInColumn: boolean;
}

interface ParseFilterReturn {
  /** Active filter IDs for a specific column (field) in the store */
  activeFilterIds: string[];

  /** Callback to update add/remove a filter in a column (field) */
  updateItemFilterCallback: ({
    newFilterItem,
    shouldReplaceFilterInColumn,
  }: UpdateItemFilterCallback) => void;
}

/** Returns the active filter for a column and a callback to change it */
export function useParseFilters({ filteredColumn }: Props): ParseFilterReturn {
  const dispatch = useAppDispatch();

  const filtersForSpecificColumn = useAppSelector(
    filtersForSpecificColumnSelector(filteredColumn)
  );

  const activeFilterIds = useMemo(
    () => filtersForSpecificColumn.map((filter) => filter.id),
    [filtersForSpecificColumn]
  );

  /** Callback to update add/remove a filter in a column (field) */
  function updateItemFilterCallback({
    newFilterItem,
    shouldReplaceFilterInColumn,
  }: UpdateItemFilterCallback): void {
    const filterPayload: FilterPayload = {
      filteredColumn,
      filter: newFilterItem,
      shouldReplaceFilterInColumn,
    };

    dispatch(updateFiltersInColumn(filterPayload));
  }

  return {
    activeFilterIds,
    updateItemFilterCallback,
  };
}
