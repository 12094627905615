import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { DropdownFilterChip } from "@components/common/faro-table/faro-table-filter/dropdown-filter-chip";
import { markupsUniqueKeysSelector } from "@store/markups/markups-selector";
import { useAppSelector } from "@store/store-helper";
import { getMarkupsSheetsFilterOptions } from "@pages/project-details/project-markups/project-markups-filters/project-markups-filter-list";
import { useMemo } from "react";
import { FilterTableEvents } from "@utils/track-event/track-event-list";

/** Show filters and apply them for project markup sheets */
export function ProjectMarkupsSheetsFilter(): JSX.Element {
  const markupsSheets = useAppSelector(
    markupsUniqueKeysSelector("areaSection")
  );
  const allFilterSheets = useMemo(
    () => getMarkupsSheetsFilterOptions(markupsSheets),
    [markupsSheets]
  );

  return (
    <DropdownFilterChip
      label={ProjectMarkupsTableHeaders.sheet}
      filteredColumn={ProjectMarkupsTableHeaders.sheet}
      items={allFilterSheets}
      shouldShowSearchBox={true}
      shouldShowCheckbox={true}
      filterTableType={FilterTableEvents.filterAnnotations}
      size="large"
    />
  );
}
