import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { withEllipsis } from "@styles/common-styles";
import { GroupHeaders } from "@components/table/groups/groups-table-utils";
import defaultGroupThumbnail from "@assets/group-thumbnail.png";
import { GroupTypes } from "@custom-types/group-types";
import { getPrettyDate } from "@utils/data-display";

interface Props {
  // TODO: Extract it in separate file or access in in the component: https://faro01.atlassian.net/browse/ST-1348
  options: {
    /** Whether the current screen is large or larger */
    isScreenLgOrLarger: boolean;

    /** Whether the current screen is medium */
    isMedium: boolean;
  };
}

/** The default padding for the columns in pixels */
const COL_DEFAULT_X_PADDING = 16;

/** The width of the thumbnail image in pixels */
const THUMBNAIL_IMAGE_WIDTH = 70;

/** Minimum column width in px */
const COLS_MIN_WIDTH_IN_PX = 100;

/** Column width in px for medium or smaller screen */
const MD_OR_SMALLER_COLUMNS_WIDTH_IN_PX = 200;

/** Column width in px for large and larger screens */
const LG_OR_LARGER_COLUMNS_WIDTH_IN_PX = 250;

/** Return all the possible columns for Groups table */
export function getGroupsColumns({
  options,
}: Props): Record<GroupHeaders, GridColDef> {
  const maxTextColumnWidth = options.isScreenLgOrLarger
    ? LG_OR_LARGER_COLUMNS_WIDTH_IN_PX
    : MD_OR_SMALLER_COLUMNS_WIDTH_IN_PX;

  const flexSetting = options.isScreenLgOrLarger ? 1 : undefined;

  return {
    [GroupHeaders.thumbnail]: {
      field: GroupHeaders.thumbnail,
      headerName: "",
      width: 190,
      // Use two times COL_DEFAULT_X_PADDING, one for each side
      minWidth: THUMBNAIL_IMAGE_WIDTH + 2 * COL_DEFAULT_X_PADDING,
      renderCell: (params: GridRenderCellParams<{ entity: GroupTypes }>) => {
        return (
          <img
            // Don't use Nullish coalescing (??) as it might happen that the thumbnailUrl is an empty string
            src={
              params.row.entity.thumbnailUrl
                ? params.row.entity.thumbnailUrl
                : defaultGroupThumbnail
            }
            style={{
              height: "50px",
              width: "70px",
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "contain",
            }}
            alt="Group thumbnail"
          />
        );
      },
    },

    [GroupHeaders.name]: {
      field: GroupHeaders.name,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: 380,
      renderCell: (params: GridRenderCellParams<{ entity: GroupTypes }>) => {
        return (
          <SphereTooltip
            title={<var>{params.row.entity.name}</var>}
            boxProps={{
              sx: {
                ...withEllipsis,
                fontWeight: "bold",
              },
            }}
          >
            <var>{params.row.entity.name}</var>
          </SphereTooltip>
        );
      },
    },

    [GroupHeaders.managers]: {
      field: GroupHeaders.managers,
      width: MD_OR_SMALLER_COLUMNS_WIDTH_IN_PX,
      minWidth: 54,
      renderCell: (params: GridRenderCellParams<{ entity: GroupTypes }>) => {
        return <var>{params.row.entity.numManagers}</var>;
      },
    },

    [GroupHeaders.projects]: {
      field: GroupHeaders.projects,
      flex: flexSetting,
      width: maxTextColumnWidth,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      renderCell: (params: GridRenderCellParams<{ entity: GroupTypes }>) => {
        return <var>{params.row.entity.numProjects}</var>;
      },
    },

    [GroupHeaders.createdDate]: {
      field: GroupHeaders.createdDate,
      flex: flexSetting,
      minWidth: COLS_MIN_WIDTH_IN_PX,
      maxWidth: maxTextColumnWidth,
      renderCell: (params: GridRenderCellParams<{ entity: GroupTypes }>) => {
        return <var>{getPrettyDate(params.row.entity.createdAt)}</var>;
      },
    },

    // A placeholder for further options
    [GroupHeaders.options]: {
      field: GroupHeaders.options,
      headerName: "",
      width: 95,
      renderCell: () => {
        return "";
      },
    },
  };
}
