import { FilterOption } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { Markup } from "@custom-types/project-markups-types";
import { MemberTypes } from "@custom-types/member-types";
import { getPrettyName, getUserInitials } from "@utils/user-utils";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { projectMarkupStatusText } from "@pages/project-details/project-markups/status/markup-status-types";
import { MarkupStatusIcon } from "@pages/project-details/project-markups/status/markup-status-icon";
import { CoreAPITypes } from "@stellar/api-logic";
import DateAdapter from "@date-io/luxon";

export function getMarkupsStatusFilterOptions(): FilterOption[] {
  const noStatusFilter: FilterOption = {
    id: "unclassified",
    filterValue: "unclassified",
    operator: "equal",
    label: projectMarkupStatusText.unclassified,
    icon: (
      <MarkupStatusIcon
        key="unclassified"
        status="unclassified"
        shouldHaveBorder={true}
      />
    ),
  };

  const statusFilters: FilterOption[] = Object.values(
    CoreAPITypes.EIssueStatus
  ).map((value) => {
    return {
      id: value,
      filterValue: value,
      operator: "equal",
      label: projectMarkupStatusText[value],
      icon: (
        <MarkupStatusIcon key={value} status={value} shouldHaveBorder={true} />
      ),
    };
  });

  return [noStatusFilter, ...statusFilters];
}

/** Returns all the filter options for markup tags */
export function getMarkupsTagsFilterOptions(
  markupsLabels: NonNullable<Markup["labels"]>[]
): FilterOption[] {
  const allFilterTags: FilterOption[] = markupsLabels.flat().map((label) => ({
    id: label.id,
    filterValue: label.id,
    label: label.name,
    operator: "includes",
  }));

  return [
    {
      id: "untagged",
      filterValue: undefined,
      label: "Untagged",
      operator: "equal",
    },
    ...allFilterTags,
  ];
}

/** Returns all the filter options for markup attachments */
export function getMarkupsAttachmentFilterOptions(): FilterOption {
  return {
    id: "hasAttachment",
    filterValue: 0,
    operator: "larger",
    label: "",
  };
}

/** Returns all the filter options for markup sheets */
export function getMarkupsSheetsFilterOptions(
  markupsSheets: NonNullable<Markup["areaSection"]>[]
): FilterOption[] {
  const allFilterSheets: FilterOption[] = markupsSheets.map((sheet) => ({
    id: sheet.id,
    filterValue: sheet.id,
    label: sheet.name,
    operator: "equal",
  }));

  return [
    {
      id: "noSheet",
      filterValue: undefined,
      label: "No Sheet",
      operator: "equal",
    },
    ...allFilterSheets,
  ];
}

/** Returns all the filter options for markup scenes */
export function getMarkupsScenesFilterOptions(
  markupsScenes: NonNullable<Markup["section"]>[]
): FilterOption[] {
  const allFilterScenes: FilterOption[] = markupsScenes.map((scene) => ({
    id: scene.id,
    filterValue: scene.id,
    label: scene.name,
    operator: "equal",
  }));

  return allFilterScenes;
}

/** Returns all the filter options for markup assignee */
export function getMarkupsAssigneesFilterOptions(
  markupsAssignees: MemberTypes[]
): FilterOption[] {
  const allFilterAssignees: FilterOption[] = markupsAssignees.map(
    (assignee) => ({
      id: assignee.identity,
      filterValue: assignee.identity,
      label: getPrettyName(assignee),
      operator: "equal",
      icon: (
        <SphereAvatar
          size={"small"}
          initials={getUserInitials(assignee) ?? ""}
          src={assignee.thumbnailUrl}
        />
      ),
    })
  );

  return [
    {
      id: "unassigned",
      filterValue: undefined,
      label: "Unassigned",
      operator: "equal",
    },
    ...allFilterAssignees,
  ];
}

/** Returns all the filter options for markup due date */
export function getMarkupsDueDateFilterOptions(): FilterOption[] {
  const dateAdapter = new DateAdapter();
  const today = dateAdapter.date();
  const startOfToday = dateAdapter.startOfDay(today);
  const endOfToday = dateAdapter.endOfDay(today);

  /* eslint-disable @typescript-eslint/no-magic-numbers -- these numbers are requited for giving duration */
  return [
    {
      id: "due-date-before-today",
      filterValue: startOfToday.valueOf(),
      label: "Before today",
      operator: "smaller",
    },
    {
      id: "due-date-today",
      filterValue: [startOfToday.valueOf(), endOfToday.valueOf()],
      label: "Today",
      operator: "between",
    },
    {
      id: "due-date-tomorrow",
      filterValue: [
        dateAdapter.addDays(startOfToday, 1).valueOf(),
        dateAdapter.addDays(startOfToday, 2).valueOf(),
      ],
      label: "Tomorrow",
      operator: "between",
    },
    {
      id: "due-date-next-7-days",
      filterValue: [
        dateAdapter.addDays(startOfToday, 0).valueOf(),
        dateAdapter.addDays(startOfToday, 7).valueOf(),
      ],
      label: "Next 7 days",
      operator: "between",
    },
    {
      id: "due-date-next-30-days",
      filterValue: [
        dateAdapter.addDays(startOfToday, 0).valueOf(),
        dateAdapter.addDays(startOfToday, 30).valueOf(),
      ],
      label: "Next 30 days",
      operator: "between",
    },
    {
      id: "due-date-next-90-days",
      filterValue: [
        dateAdapter.addDays(startOfToday, 0).valueOf(),
        dateAdapter.addDays(startOfToday, 90).valueOf(),
      ],
      label: "Next 90 days",
      operator: "between",
    },
    {
      id: "due-date-no-date",
      filterValue: undefined,
      label: "No date",
      operator: "equal",
    },
  ];
  /* eslint-enable @typescript-eslint/no-magic-numbers */
}
