// keyCombinationsMap.ts
import { QueryParams } from "@router/route-params";
import { runtimeConfig } from "@src/runtime-config";

export interface KeyCombination {
  /** True if it is required that alt key was pressed */
  hasAltKey?: boolean;

  /** True if it should not work in production */
  shouldSkipProd?: boolean;

  /** True if only internal FARO users should use it in prod */
  isOnlyForInternalUsersInProd?: boolean;

  /** The action to take when this combination is pressed */
  action: (setQueryParam: (key: QueryParams, value: string) => void) => void;
}

/**
 *  "Alt+KeyA" - combination to active the alpha feature flag
 *  "Alt+KeyB" - combination to active the enable feature flag
 *  "Alt+KeyN" - combination to disable all the feature flag
 */
export const keyCombinationsMap: Map<string, KeyCombination> = new Map([
  [
    "Alt+KeyA",
    {
      hasAltKey: true,
      shouldSkipProd: true,
      action: (setQueryParam) => setQueryParam(QueryParams.enableAlpha, "true"),
    },
  ],
  [
    "Alt+KeyB",
    {
      hasAltKey: true,
      shouldSkipProd: false,
      isOnlyForInternalUsersInProd: true,
      action: (setQueryParam) => setQueryParam(QueryParams.enableBeta, "true"),
    },
  ],
  [
    "Alt+KeyN",
    {
      hasAltKey: true,
      shouldSkipProd: true,
      action: (setQueryParam) => {
        const isProduction = runtimeConfig.appEnv
          .toLowerCase()
          .includes("prod");
        // Since we don't want any user in production to find out about the alpha feature flag
        // we won't show it with this key combination
        if (!isProduction) {
          setQueryParam(QueryParams.enableAlpha, "false");
        }
        setQueryParam(QueryParams.enableBeta, "false");
      },
    },
  ],
]);
