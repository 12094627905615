/**
 * Returns true if the end date is newer than the compare date.
 *
 * @param date The date to compare.
 * @param compareDate The date to compare to.
 */
export function isDateNewer(date: string, compareDate: string): boolean {
  return new Date(date) > new Date(compareDate);
}

/**
 * Returns true if the end date is newer than today.
 *
 * @param endDate The end date to compare to today.
 */
export function shouldShowNewTag(endDate?: string): boolean {
  if (!endDate) {
    return false;
  }
  const today = new Date().toISOString();
  return !isDateNewer(today, endDate);
}

/** Returns true iff the passed date is older than the current date */
export function isDateOverdue(date: string): boolean {
  return new Date() > new Date(date);
}

/**
 * Checks if the app was initialized within the last 24 hours,
 */
export function wasTodayInitialized(appInitTime: string | null): boolean {
  return (
    !!appInitTime &&
    // Check if the app was initialized in the last 24 hours
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- 24 hours in milliseconds
    Math.abs(Date.now() - parseInt(appInitTime, 10)) < 1000 * 60 * 60 * 24
  );
}
