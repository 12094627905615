import {
  NumberFilterOption,
  ArrayFilterOption,
  StringFilterOption,
  BetweenFilterOption,
  EntityValue,
} from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";

/** Get the comparison logic for the number filter option */
export function getNumberFilterOptionComparisonLogic<
  T extends NumberFilterOption,
  K extends EntityValue<T>
>(filterOption: NumberFilterOption): (entityValue: K) => boolean {
  switch (filterOption.operator) {
    case "equal":
      return (entityValue: K) => entityValue === filterOption.filterValue;

    case "larger":
      return (entityValue: K) => {
        return entityValue > filterOption.filterValue;
      };

    case "largerOrEqual":
      return (entityValue: K) => {
        return entityValue >= filterOption.filterValue;
      };

    case "smaller":
      return (entityValue: K) => {
        return entityValue < filterOption.filterValue;
      };

    case "smallerOrEqual":
      return (entityValue: K) => {
        return entityValue <= filterOption.filterValue;
      };
  }
}

/** Get the comparison logic for the string filter option */
export function getStringFilterOptionComparisonLogic<
  T extends StringFilterOption,
  K extends EntityValue<T>
>(filterOption: T): (entityValue: K) => boolean {
  return (entityValue: K) => entityValue === filterOption.filterValue;
}

/** Get the comparison logic for the array filter option */
export function getArrayFilterOptionComparisonLogic<
  T extends ArrayFilterOption,
  K extends EntityValue<T>
>(filterOption: T): (entityValue: K) => boolean {
  return (entityValue: K) => {
    if (filterOption.filterValue === undefined || entityValue === undefined) {
      return false;
    }

    return entityValue.includes(filterOption.filterValue);
  };
}

/** Get the comparison logic for the between filter option */
export function getBetweenFilterOptionComparisonLogic<
  T extends BetweenFilterOption,
  K extends EntityValue<T>
>(filterOption: T): (entityValue: K) => boolean {
  return (entityValue: K) => {
    if (filterOption.filterValue === undefined || entityValue === undefined) {
      return false;
    }
    return (
      entityValue >= filterOption.filterValue[0] &&
      entityValue < filterOption.filterValue[1]
    );
  };
}
