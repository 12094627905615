import { createContext, useContext } from "react";

/** Context providing authentication information */
type AuthContextData = {
  /** Requests the user to perform a login */
  requestLogin(): void;

  /** Logs out the user */
  logout(): void;

  /** Whether the current user is currently logged in */
  isLoggedIn: boolean;
};

export const AuthContext = createContext<AuthContextData | undefined>(
  undefined
);

/**
 * @returns the current AuthContext or error if not available
 */
export function useAuthContext(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error(
      "useAuthContext() must be used inside the AuthProvider component"
    );
  }

  return context;
}
