import { Box, Chip } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SphereStatusChip } from "@components/common/sphere-status-chip";
import CheckIcon from "@mui/icons-material/Check";

interface AddOnsExtensionFooterProps {
  /** Date from extensions */
  hasNewChip: boolean;

  /** Add-on is active or not */
  isActive?: boolean;
}

/** Renders the extension footer component */
export function AddOnsExtensionFooter({
  hasNewChip,
  isActive,
}: AddOnsExtensionFooterProps): JSX.Element {
  return (
    <Box sx={{ display: "flex", gap: "8px", mt: "36px" }}>
      {hasNewChip && (
        <Chip
          data-testid="add-ons-extensions-new-chip"
          label="NEW"
          color={"warning"}
          size="small"
          sx={{
            fontSize: "10px",
            height: "22px",
          }}
        />
      )}
      {isActive && (
        <SphereStatusChip
          icon={
            <CheckIcon
              sx={{
                width: "10px",
                height: "10px",
              }}
            />
          }
          label="ACTIVE"
          color={sphereColors.blue500}
          backgroundColor={sphereColors.blue100}
          styleChip={{
            fontSize: "10px",
            cursor: "pointer",
            fontWeight: "bold",
            "& .MuiChip-icon": {
              marginLeft: "8px",
            },
            height: "22px",
          }}
        />
      )}
    </Box>
  );
}
