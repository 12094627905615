import { ExportAsCSV } from "@utils/export-as-csv";
import { GroupTypes } from "@custom-types/group-types";
import { TableType } from "@utils/track-event/track-event-list";
import { downloadGroupDataAsCSV } from "@utils/csv-data-export";

interface Props {
  /** List of selected groups in table */
  selectedGroups: GroupTypes[];
}

/** Button to export selected groups as CSV */
export function ExportGroupsAsCSV({ selectedGroups }: Props): JSX.Element {
  return (
    <ExportAsCSV
      trackingProps={{
        dataType: TableType.groups,
        numberOfEntities: selectedGroups.length,
      }}
      csvExportHandler={() => downloadGroupDataAsCSV(selectedGroups)}
      toastMessage={{
        success: "Successfully exported groups as CSV.",
        error: "Cannot export groups. Please try again.",
      }}
      buttonTooltipText="Export groups"
    />
  );
}
