import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { DropdownFilterChip } from "@components/common/faro-table/faro-table-filter/dropdown-filter-chip";
import { getMarkupsDueDateFilterOptions } from "@pages/project-details/project-markups/project-markups-filters/project-markups-filter-list";
import { FilterTableEvents } from "@utils/track-event/track-event-list";

/** Show filters and apply them for project markup due date */
export function ProjectMarkupsDueDateFilter(): JSX.Element {
  return (
    <DropdownFilterChip
      label={ProjectMarkupsTableHeaders.dueDate}
      filteredColumn={ProjectMarkupsTableHeaders.dueDate}
      items={getMarkupsDueDateFilterOptions()}
      shouldShowCheckbox={false}
      shouldShowSearchBox={false}
      shouldShowCheckIconOnRight={true}
      filterTableType={FilterTableEvents.filterAnnotations}
    />
  );
}
