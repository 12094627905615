import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { SdbProject } from "@custom-types/project-types";
import { Grid, Skeleton, SxProps } from "@mui/material";
import { DateTimeUtils } from "@stellar/web-core";
import { CommonStyles } from "@styles/common-styles";
import { DASH } from "@utils/ui-utils";

interface Props {
  /** The project to display in the ProjectOverview */
  project?: SdbProject | null;

  /** Whether the project is still loading and skeleton should be shown instead */
  isLoading: boolean;
}

/** Defines the style of the border in between date sections */
const DEFAULT_BORDER = CommonStyles.Borders.gray200Divider;

/** Defines the default spacing between date sections */
const DEFAULT_SPACING = "20px";

/** Defines the margins in between the date sections */
const DATE_MARGINS: SxProps = {
  mt: "4px",
  mb: DEFAULT_SPACING,
};

/**
 * Shows the date details for a project. These include the creation, last captured and last updated dates.
 * They are displayed right below the main details of the project.
 */
export function DateDetails({ project, isLoading }: Props): JSX.Element {
  if (isLoading || !project) {
    return <Skeleton variant="rectangular" height="45px" sx={DATE_MARGINS} />;
  }

  return (
    <Grid container justifyContent="space-between" sx={DATE_MARGINS}>
      <Grid item xs={12} lg={4}>
        <FaroTextField
          label="Creation Date"
          initialValue={DateTimeUtils.format({ date: project.createdAt })}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          borderLeft: {
            lg: DEFAULT_BORDER,
          },
          py: {
            xs: DEFAULT_SPACING,
            lg: 0,
          },
          px: {
            lg: DEFAULT_SPACING,
          },
        }}
      >
        <FaroTextField
          label="Last Captured"
          initialValue={
            project.lastCapturedAt
              ? DateTimeUtils.format({ date: project.lastCapturedAt })
              : DASH
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          borderLeft: {
            lg: DEFAULT_BORDER,
          },
          px: {
            lg: DEFAULT_SPACING,
          },
        }}
      >
        <FaroTextField
          label="Last Updated"
          initialValue={DateTimeUtils.format({ date: project.modifiedAt })}
        />
      </Grid>
    </Grid>
  );
}
