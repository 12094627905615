import { Stack } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** User Module URL that will be the source for the iFrame */
  iFrameUrl: string | undefined;
}

/** User Module embedded in an iFrame */
export function UserModule({ iFrameUrl }: Props): JSX.Element {
  return (
    <Stack
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        alignItems: "center",
        backgroundColor: sphereColors.userModuleBackground,
      }}
      direction={"column"}
    >
      <iframe
        title="user-module"
        style={{
          width: "100%",
          maxWidth: "800px",
          flexGrow: 1,
          border: "none",
        }}
        src={iFrameUrl}
      />
    </Stack>
  );
}
