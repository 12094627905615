import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { ErrorWithTitle } from "@context-providers/error-boundary/error-types";
import WarningAmberIcon from "@mui/icons-material/Warning";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { HEADER_HEIGHT } from "@utils/ui-utils";
import { SanitizedHtmlTag } from "@components/common/sanitized-html-tag";
import { useAppDispatch } from "@store/store-helper";
import { removeOne } from "@store/errors/errors-slice";

interface Props {
  /** Error title with the actual error */
  error: ErrorWithTitle;

  /** Click handler for when user clicks on reload */
  onReload?(): void;

  /** Click handler for when user clicks on go to home */
  onGoHome?(): void;
}

/**
 * Displays an error message with the ability to go to home page or reload the page
 */
export function ErrorPage({ error, onReload, onGoHome }: Props): JSX.Element {
  const dispatch = useAppDispatch();

  function onReloadHandler(): void {
    dispatch(removeOne(error.id));
    onReload && onReload();
  }

  function onGoHomeHandler(): void {
    dispatch(removeOne(error.id));
    onGoHome && onGoHome();
  }

  return (
    <Box
      key={error.id}
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight={`calc(100vh - ${HEADER_HEIGHT})`}
      width="100%"
      gap={2}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <WarningAmberIcon
          sx={{
            marginRight: "10px",
          }}
        />
        <Typography variant="h6">{error.title}</Typography>
      </Stack>

      <Grid item>
        <Typography component="div">
          <b>Developer Error Message:</b>
          <SanitizedHtmlTag markup={getErrorDisplayMarkup(error.error)} />
        </Typography>
      </Grid>
      <div>
        <Button
          onClick={onGoHomeHandler}
          variant="outlined"
          sx={{ mr: "40px" }}
        >
          Return to Home
        </Button>

        <Button onClick={onReloadHandler} variant="outlined">
          Reload
        </Button>
      </div>
    </Box>
  );
}
