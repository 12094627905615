import { useEffect, useState } from "react";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { resetSdbBackgroundTasksState } from "@store/sdb-background-tasks/sdb-background-tasks-slice";
import {
  areSdbBackgroundTasksRunningSelector,
  backgroundTasksFetchingStatusSelector,
} from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { updateBackgroundTasks } from "@store/sdb-background-tasks/sdb-background-tasks-thunk";
import { FetchingStatus } from "@store/store-types";

/** Default interval at which the polling is executed: 60 seconds */
const DEFAULT_POLLING_INTERVAL = 60000;

/** Polling interval when there is a running background task that has progress data: 30 seconds */
const RUNNING_TASK_POLLING_INTERVAL = 30000;

interface Props {
  /** Id of the current project */
  projectId: string;
}

interface UseBackgroundTasksTracker {
  /** Whether the background tasks are loading for the first time */
  isLoading: boolean;
}

/**
 * Requests background tasks to the progress API at a regular polling interval and updates the store.
 */
export function useBackgroundTasksTracker({
  projectId,
}: Props): UseBackgroundTasksTracker {
  const [pollingInterval, setPollingInterval] = useState<number>(
    DEFAULT_POLLING_INTERVAL
  );

  const progressApiClient = useProgressApiClient({ projectId });
  const dispatch = useAppDispatch();

  const isSdbBackgroundTasksRunning = useAppSelector(
    areSdbBackgroundTasksRunningSelector
  );
  const backgroundTasksFetchingStatus = useAppSelector(
    backgroundTasksFetchingStatusSelector
  );
  const { handleErrorWithToast } = useErrorContext();

  const projectApiClient = useProjectApiClient({
    projectId,
  });

  // Updates the polling interval depending on if there is a running background task in the store
  // This way we can reduce the polling time when a background task is running and sending progress data.
  useEffect(() => {
    if (isSdbBackgroundTasksRunning) {
      setPollingInterval(RUNNING_TASK_POLLING_INTERVAL);
    } else {
      setPollingInterval(DEFAULT_POLLING_INTERVAL);
    }

    return () => setPollingInterval(DEFAULT_POLLING_INTERVAL);
  }, [isSdbBackgroundTasksRunning]);

  // Regularly fetches the background tasks from the progress API and updates the store
  useEffect(() => {
    dispatch(
      updateBackgroundTasks({
        progressApiClient,
        projectApiClient,
        beforePage: null,
      })
    );

    // Polling interval to fetch the most recent background tasks again
    // Don't set the beforePage token after polling the background tasks, because we might be on a different page
    // and want to keep the current page token
    const pollingIntervalId = setInterval(
      () =>
        dispatch(
          updateBackgroundTasks({
            progressApiClient,
            projectApiClient,
            beforePage: null,
            shouldOverrideBeforePage: false,
          })
        ),
      pollingInterval
    );

    return () => {
      clearInterval(pollingIntervalId);
    };
  }, [
    dispatch,
    handleErrorWithToast,
    pollingInterval,
    progressApiClient,
    projectApiClient,
    projectId,
  ]);

  // Resets the background tasks store on component unmount
  useEffect(() => {
    return () => {
      dispatch(resetSdbBackgroundTasksState());
    };
  }, [dispatch]);

  return {
    isLoading:
      backgroundTasksFetchingStatus === FetchingStatus.uninitialized ||
      backgroundTasksFetchingStatus === FetchingStatus.pending,
  };
}
