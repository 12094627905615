import { ErrorHandlingFunction } from "@context-providers/error-boundary/error-handling-context";
import { runtimeConfig } from "@src/runtime-config";
import { isValidEmail } from "@utils/member-utils";
import { isObject } from "@utils/object-utils";

/** Message received from UserModule asking the page to redirect to a new url */
type RedirectMessage = {
  /** New url the app should redirect to */
  redirect: string;
};

/** Message received from UserModule when the UserStatus changes */
type UserStatusMessage = {
  /** Property used to identify this was a UserStatusMessage from the user module, always true */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  userStatus: true;

  /** Optional property returned by the login page of the user module. Returns whether the user is logged in */
  isUserLoggedIn?: boolean;

  /** Return by the logout page of the user module. Returns whether the logout operation was successful */
  isUserLoggedOut?: boolean;
};

/** Message received from the User Module with status on the user account */
type UserAccountMessage = {
  /** Property used to identify that this is a UserAccountMessage coming from the User Module */
  isUserAccountStatus: true;

  /** Optional property sent by the User Module when the user account is incomplete */
  isUserAccountIncomplete?: boolean;
};

/** All UserModule messages we currently handle */
export type IFrameMessage =
  | RedirectMessage
  | UserStatusMessage
  | UserAccountMessage;

/**
 * @param message the UserModule message
 * @returns true if the passed UserModule message is a RedirectMessage
 */
export function isRedirectMessage(
  message: IFrameMessage
): message is RedirectMessage {
  return isObject(message) && "redirect" in message;
}

/**
 * @param message the UserModule message
 * @returns true if the passed UserModule message is a UserStatusMessage
 */
export function isUserStatusMessage(
  message: IFrameMessage
): message is UserStatusMessage {
  return isObject(message) && "userStatus" in message;
}

/**
 * @param message the UserModule message
 * @returns true if the passed UserModule message is a UserAccountMessage
 */
export function isUserAccountMessage(
  message: IFrameMessage
): message is UserAccountMessage {
  return isObject(message) && "isUserAccountStatus" in message;
}

interface GetLoginUrlProps {
  /** User module base url */
  userModuleUrl: string;

  /** Whether email should be passed as url param to the user module */
  shouldGetLoginEmailParam?: boolean;

  /** How to handle the error if any */
  handleError: ErrorHandlingFunction;
}

/**
 * @returns The URL to the login page of the user module that redirects back to the current page
 *
 * In local development it will redirect to the main page of the deployed environment.
 * @param userModuleUrl Base URL of the user module
 * @param shouldGetLoginEmailParam If true it will read the email from the url to prefill the user module login form
 */
export function getLoginUrl({
  userModuleUrl,
  shouldGetLoginEmailParam,
  handleError,
}: GetLoginUrlProps): URL | undefined {
  const appUrl = window.location.href;

  const loginUrlParams = new URLSearchParams();

  loginUrlParams.append("os", "web");

  // Get the prefilled login email query param and pass it to the user module
  if (shouldGetLoginEmailParam) {
    const sdbParams = new URLSearchParams(appUrl.split("?")[1]);

    const loginEmailKey = "login_email";
    const loginEmailValue = sdbParams.get(loginEmailKey);

    // If the email param was passed and has a valid email format then add it to the login url
    if (loginEmailValue && isValidEmail(loginEmailValue)) {
      loginUrlParams.append("email", loginEmailValue);
    }
  }

  const appUrlObject = new URL(appUrl);
  // Remove `direct_login` and `login_email` params from the app URL
  // so they do not clutter the app URL after a successful login
  appUrlObject.searchParams.delete("direct_login");
  appUrlObject.searchParams.delete("login_email");

  loginUrlParams.append(
    "redirectUrl",
    process.env.NODE_ENV === "production"
      ? appUrlObject.href
      : runtimeConfig.urls.dashboard20Url
  );

  // Construct url
  try {
    const loginUrl = new URL(
      `#login?${loginUrlParams.toString()}`,
      userModuleUrl
    );

    return loginUrl;
  } catch (error) {
    handleError({
      id: `getLoginUrl-${Date.now().toString()}`,
      title: "Malformed login url: ",
      error,
    });
    return undefined;
  }
}
