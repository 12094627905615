import { Box } from "@mui/material";
import { useAppSelector } from "@store/store-helper";
import { filtersForSpecificColumnSelector } from "@store/table/table-selector";
import { sphereColors } from "@styles/common-colors";
import { FilterColumnNames } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";

interface Props {
  /** The column in which filtering will apply to */
  filteredColumn: FilterColumnNames;
}

/** Icon to show number of filters next to a chip */
export function NumberOfFiltersForColumnIcon({
  filteredColumn,
}: Props): JSX.Element | null {
  const filtersForSpecificColumn = useAppSelector(
    filtersForSpecificColumnSelector(filteredColumn)
  );

  if (filtersForSpecificColumn.length === 0) {
    return null;
  }

  return (
    <Box
      component="div"
      sx={{
        width: "18px",
        height: "18px",
        backgroundColor: sphereColors.blue400,
        color: sphereColors.pureWhite,
        textAlign: "center",
        borderRadius: "50%",
        marginRight: "6px",
      }}
    >
      <var>{filtersForSpecificColumn.length}</var>
    </Box>
  );
}
