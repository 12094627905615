/**
 * Checks if the given value is an object.
 *
 * @param value the value to check
 * @returns true if the given value is an object
 */
export function isObject(value: unknown): value is object {
  return (
    value !== null && typeof value === "object" && value.constructor === Object
  );
}
