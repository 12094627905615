import {
  UploadedFile,
  UploadedFileResponse,
  UploadFailedFile,
} from "@custom-types/file-upload-types";

/** Whether the uploadedFileResponse is type of UploadedFile */
export function isUploadedFile(
  uploadedFileResponse: UploadedFileResponse
): uploadedFileResponse is UploadedFile {
  return "downloadUrl" in uploadedFileResponse && "md5" in uploadedFileResponse;
}

/** Whether the uploadedFileResponse is type of UploadFailedFile */
export function isUploadFailedFile(
  uploadedFileResponse: UploadedFileResponse
): uploadedFileResponse is UploadFailedFile {
  return "errorMessage" in uploadedFileResponse;
}
