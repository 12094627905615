import { FaroTable } from "@components/common/faro-table/faro-table";
import { RegistrationRevision } from "@faro-lotv/service-wires";
import { fetchingMembersFlagsSelector } from "@store/members/members-selector";
import { fetchingProjectsFlagsSelector } from "@store/projects/projects-selector";
import { useAppSelector } from "@store/store-helper";
import { useMemo } from "react";
import { PreparedDataTableHeaders } from "@pages/project-details/project-data-management/prepared-data/prepared-data-types";
import { getPreparedDataTableColumns } from "@pages/project-details/project-data-management/prepared-data/prepared-data-table-utils";
import { isRegistrationRevisionPreparing } from "@pages/project-details/project-data-management/prepared-data/prepared-data-utils";
import { sphereColors } from "@styles/common-colors";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";

/** The columns to display in the table */
const COLUMNS: PreparedDataTableHeaders[] = [
  PreparedDataTableHeaders.status,
  PreparedDataTableHeaders.createdBy,
  PreparedDataTableHeaders.createdAt,
  PreparedDataTableHeaders.actions,
];

interface Props {
  /** List of prepared data entities */
  preparedData: RegistrationRevision[];

  /** Whether the prepared data is being fetched for the first time */
  isFetchingForTheFirstTime: boolean;
}

/** Renders the table that displays prepared data */
export function PreparedDataTable({
  preparedData,
  isFetchingForTheFirstTime,
}: Props): JSX.Element {
  const { companyMembers, projectMembers } = useDataManagementContext();

  const { isFetchingCompanyMembers } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const { isFetchingProjects: isFetchingProjectMembers } = useAppSelector(
    fetchingProjectsFlagsSelector
  );

  const isLoading = useMemo(() => {
    return (
      isFetchingForTheFirstTime ||
      isFetchingCompanyMembers ||
      isFetchingProjectMembers
    );
  }, [
    isFetchingForTheFirstTime,
    isFetchingCompanyMembers,
    isFetchingProjectMembers,
  ]);

  return (
    <FaroTable
      entities={preparedData}
      uniqueIdKey={"id"}
      isLoading={isLoading}
      columns={COLUMNS.map(
        (column) =>
          getPreparedDataTableColumns({
            companyMembers,
            projectMembers,
          })[column]
      )}
      rowHeight={54}
      shouldHideCheckbox={true}
      rowClassName={(rowData) => {
        return isRegistrationRevisionPreparing(rowData.row.entity)
          ? "prepared-data-isPreparing"
          : "";
      }}
      sx={{
        // Set background color of preparing rows
        "& .prepared-data-isPreparing": {
          background: sphereColors.blue50,
          "&:hover": {
            background: sphereColors.blue50,
          },
        },
        // Set opacity of all cells in preparing rows
        "& .prepared-data-isPreparing *": {
          opacity: "0.8",
        },
        // Especial opacity for the last column that displays actions/indicator
        "& .prepared-data-isPreparing *:last-child *": {
          opacity: "1",
        },
      }}
    />
  );
}
