import { EmptyPage } from "@components/common/empty-page/empty-page";
import NoAnnotationsIcon from "@assets/icons/new/no_annotations.svg?react";
import { TABS_CONTENT_PADDING } from "@utils/ui-utils";
import { Grid } from "@mui/material";
import { ProjectMarkupsTable } from "@pages/project-details/project-markups/project-markups-table";
import { useProjectMarkups } from "@hooks/project-markups/use-project-markups";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { useMembersUtils } from "@hooks/use-member-utils";
import { useMemo } from "react";
import { ProjectMarkupsDetails } from "@pages/project-details/project-markups/project-markups-details";
import { ProjectMarkupsSendFeedback } from "@pages/project-details/project-markups/project-markups-send-feedback";
import { MarkupProvider } from "@context-providers/markup/markup-context";
import FailedIcon from "@assets/icons/failed_32px.svg?react";
import { useAppSelector } from "@store/store-helper";
import { hasFetchedMarkupsIElementsSelector } from "@store/sdb-i-elements/sdb-i-elements-selectors";
import { ProjectMarkupBulkActions } from "@pages/project-details/project-markups/project-markups-bulk-actions/project-markup-bulk-actions";

/**
 * Renders the project markups as a table
 */
export function ProjectMarkups({ projectId }: BaseProjectIdProps): JSX.Element {
  const hasFetchedMarkupsIElements = useAppSelector(
    hasFetchedMarkupsIElementsSelector
  );

  const { markups, isFetchingMarkups } = useProjectMarkups({ projectId });

  const { isFetchingCompanyMembers, isFetchingProjectMembers } =
    useMembersUtils();

  /** Flag that is set to true while all the data required to show the markups tab is not yet ready */
  const isLoading = useMemo(() => {
    return (
      isFetchingProjectMembers || isFetchingMarkups || isFetchingCompanyMembers
    );
  }, [isFetchingCompanyMembers, isFetchingProjectMembers, isFetchingMarkups]);

  const buttonComponents = ProjectMarkupBulkActions({
    markups,
    projectId,
  });

  /** Content to be shown in the page, either empty/error page or markups table */
  const content = useMemo(() => {
    return markups.length === 0 && !isLoading ? (
      // If the markups are loaded and the project has no markups, show an empty page.
      // If the markups were not loaded because the request failed then show an error page.
      // TODO: Implement the final design for error pages: https://faro01.atlassian.net/browse/ST-1741
      <EmptyPage
        title={hasFetchedMarkupsIElements ? "No annotations" : "Error"}
        subtitle={
          hasFetchedMarkupsIElements
            ? "There aren't annotations to show for this project."
            : "Failed to fetch the project annotations. Please reload the page and try again."
        }
        icon={hasFetchedMarkupsIElements ? NoAnnotationsIcon : FailedIcon}
      />
    ) : (
      <MarkupProvider projectId={projectId} markups={markups}>
        <ProjectMarkupsSendFeedback shouldShowSkeleton={isLoading} />
        <ProjectMarkupsDetails shouldShowSkeleton={isLoading} />
        <ProjectMarkupsTable
          isLoading={isLoading}
          requiredColumns={[
            ProjectMarkupsTableHeaders.name,
            ProjectMarkupsTableHeaders.assignee,
            ProjectMarkupsTableHeaders.dueDate,
            ProjectMarkupsTableHeaders.status,
            ProjectMarkupsTableHeaders.location,
            ProjectMarkupsTableHeaders.sheet,
            ProjectMarkupsTableHeaders.scene,
            ProjectMarkupsTableHeaders.syncWith,
            ProjectMarkupsTableHeaders.tags,
            ProjectMarkupsTableHeaders.attachments,
            ProjectMarkupsTableHeaders.options,
          ]}
          bulkActionButtons={buttonComponents}
        />
      </MarkupProvider>
    );
  }, [
    buttonComponents,
    hasFetchedMarkupsIElements,
    isLoading,
    markups,
    projectId,
  ]);

  return (
    <Grid container sx={{ mt: TABS_CONTENT_PADDING, width: "100%" }}>
      <Grid item width="100%">
        {content}
      </Grid>
    </Grid>
  );
}
