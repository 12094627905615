import { useAppSelector } from "@store/store-helper";
import { TableType } from "@utils/track-event/track-event-list";
import { filterModelForTableSelector } from "@store/table/table-selector";
import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { useCallback } from "react";
import {
  isArrayFilterOption,
  isBetweenFilterOption,
  isEmptyFilterOption,
  isNumberFilterOption,
  isStringFilterOption,
  isStringArray,
} from "@custom-types/type-guards";
import {
  getArrayFilterOptionComparisonLogic,
  getBetweenFilterOptionComparisonLogic,
  getNumberFilterOptionComparisonLogic,
  getStringFilterOptionComparisonLogic,
} from "@components/common/faro-table/faro-table-filter/faro-table-filter-utils";

/** Returns a function comparing the filter with the existing value(entity) in given column returning a boolean */
export function useGetFilterLogicsForColumn<T>(
  tableType: TableType
): (entity: T, column: ProjectMarkupsTableHeaders) => boolean {
  const filterModelForTable = useAppSelector(
    filterModelForTableSelector(tableType)
  );

  return useCallback(
    (entity: T, column: ProjectMarkupsTableHeaders) => {
      const filterOptions = filterModelForTable[column];
      if (filterOptions === undefined) {
        return true;
      }
      return filterOptions.some((filterOption) => {
        if (isEmptyFilterOption(filterOption) && entity === undefined) {
          return true;
        } else if (isArrayFilterOption(filterOption) && isStringArray(entity)) {
          return getArrayFilterOptionComparisonLogic(filterOption)(entity);
        } else if (
          isBetweenFilterOption(filterOption) &&
          typeof entity === "number"
        ) {
          return getBetweenFilterOptionComparisonLogic(filterOption)(entity);
        } else if (
          isStringFilterOption(filterOption) &&
          typeof entity === "string"
        ) {
          return getStringFilterOptionComparisonLogic(filterOption)(entity);
        } else if (
          isNumberFilterOption(filterOption) &&
          typeof entity === "number"
        ) {
          return getNumberFilterOptionComparisonLogic(filterOption)(entity);
        }
        return false;
      });
    },
    [filterModelForTable]
  );
}
