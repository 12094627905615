import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { PageInfoBar } from "@components/common/page-info-bar";
import { BaseGroupDetailsProps } from "@custom-types/group-types";
import { InviteMemberToGroup } from "@pages/group-details/invite-member-to-group";
import { GroupTabs } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { selectedGroupMembersSelector } from "@store/groups/groups-selector";
import { useAppSelector } from "@store/store-helper";
import { useHasUserValidRoleGroupLevel } from "@hooks/access-control/use-has-user-valid-role-group-level";
import { useMemo } from "react";

interface Props extends Partial<BaseGroupDetailsProps> {
  /** Flag whether the content should be shown as skeletons because it is still loading */
  isLoading?: boolean;
}

/**
 * Info bar for the group details tabs
 */
export function GroupDetailsInfoBar({
  group,
  isLoading = false,
}: Props): JSX.Element {
  const { groupTabs } = useAppParams();

  const selectedGroupMembers = useAppSelector(selectedGroupMembersSelector);
  const { canInviteUsersToGroup } = useHasUserValidRoleGroupLevel({
    selectedGroup: group ?? null,
  });

  const shouldShowActionButtons = useMemo(() => {
    if (!group || isLoading) {
      return false;
    }

    return groupTabs === GroupTabs.managers && canInviteUsersToGroup;
  }, [canInviteUsersToGroup, group, groupTabs, isLoading]);

  if (!group || isLoading) {
    // Early exit with a loading skeleton if the group is not yet loaded
    return <PageInfoBar isLoading={true} />;
  }

  return (
    <PageInfoBar
      items={[
        {
          columnSize: 3,
          content: (
            <FaroTextField
              label="Group name"
              initialValue={group.name}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          columnSize: 6,
          content: (
            <FaroTextField
              label="Managers"
              initialValue={selectedGroupMembers.length}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
        {
          columnSize: 6,
          content: (
            <FaroTextField
              label="Active projects"
              initialValue={group.numProjects}
              faroVariant="main"
              fontSize="18px"
            />
          ),
        },
      ]}
      action={
        // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to keep the position of items constant
        shouldShowActionButtons ? <InviteMemberToGroup group={group} /> : <></>
      }
    />
  );
}
