import { ReactSetStateFunction } from "@custom-types/types";
import { useMediaQueryList } from "@hooks/use-media-query";
import { Stack, SvgIcon, Typography } from "@mui/material";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { addTransparency } from "@utils/ui-utils";
import { useMemo } from "react";
import FilterIcon from "@assets/icons/new/filter_24px.svg?react";
import FilterWithBadgeIcon from "@assets/icons/new/filter_with_badge_24px.svg?react";
import { isAnyFilterAppliedInCurrentTableSelector } from "@store/table/table-selector";
import { useAppSelector } from "@store/store-helper";

interface Props {
  /** Whether to show filter buttons or not */
  shouldShowFilterButtons?: boolean;

  /** Set whether to show filter buttons or not */
  setShouldShowFilterButtons?: ReactSetStateFunction<boolean>;
}

/** The filter title and icon shown at the beginning of filter toolbar */
export function FilterToolbarTitle({
  shouldShowFilterButtons = true,
  setShouldShowFilterButtons,
}: Props): JSX.Element {
  const { isScreenXsAndSmall } = useMediaQueryList();
  const isAnyFilterApplied = useAppSelector(
    isAnyFilterAppliedInCurrentTableSelector
  );

  /** Whether filter chips are visible on small screen or not */
  const areFilterChipsVisibleInSmallScreen =
    isScreenXsAndSmall && shouldShowFilterButtons;

  const filterIcon = useMemo(() => {
    if (isScreenXsAndSmall && isAnyFilterApplied && !shouldShowFilterButtons) {
      return (
        <SvgIcon
          inheritViewBox
          component={FilterWithBadgeIcon}
          sx={{ width: "18px", height: "18px" }}
        />
      );
    }
    return (
      <SvgIcon
        inheritViewBox
        component={FilterIcon}
        sx={{ width: "18px", height: "18px" }}
      />
    );
  }, [isAnyFilterApplied, isScreenXsAndSmall, shouldShowFilterButtons]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      onClick={() => setShouldShowFilterButtons?.(!shouldShowFilterButtons)}
      sx={{
        cursor: isScreenXsAndSmall ? "pointer" : "default",
        px: "10px",
        py: "6px",
        "&:hover": {
          backgroundColor: isScreenXsAndSmall
            ? addTransparency({
                color: sphereColors.gray500,
                alpha: EDecimalToHex.twentySix,
              })
            : "",
        },
        backgroundColor: areFilterChipsVisibleInSmallScreen
          ? addTransparency({
              color: sphereColors.gray500,
              alpha: EDecimalToHex.thirtyEight,
            })
          : "",

        color: areFilterChipsVisibleInSmallScreen
          ? sphereColors.blue500
          : sphereColors.black,
      }}
    >
      {filterIcon}
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 700,
          ml: "8px",
        }}
      >
        Filters
      </Typography>
    </Stack>
  );
}
