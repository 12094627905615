import { Box, SxProps } from "@mui/material";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { DateTimeUtils } from "@stellar/web-core";
import { formatUserRoleType } from "@utils/data-display";
import { useMediaQueryList } from "@hooks/use-media-query";
import { useTableColumnWidth } from "@hooks/use-table-column-width";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { PropsWithChildren, useState } from "react";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroIconButton } from "@components/common/faro-icon-button";
import DownArrow from "@assets/icons/generic-down_l.svg?react";
import UpArrow from "@assets/icons/generic-up_l.svg?react";
import {
  ACTION_BTN_SIZE,
  ACTION_ICON_SIZE,
} from "@components/common/project-actions";
import { MemberGroupProjects } from "@components/common/member/member-group-projects";
import { DefaultTableSizeOptions } from "@components/common/faro-table/faro-table-types";

/** gray100 color with opacity at 0.5 */
const gray100op50 = sphereColors.gray50;

interface MemberGroupsTableProps {
  /** Company member groups */
  groups: SphereDashboardAPITypes.CompanyMemberGroup[];
}

/** Renders a table with the groups of a company member */
export function MemberGroupsTable({
  groups,
}: MemberGroupsTableProps): JSX.Element {
  const [expandedGroups, setExpandedGroups] = useState<APITypes.GroupId[]>([]);

  return (
    <Box component="div">
      {groups.map((group) => (
        <Box component={"div"} key={group.id}>
          <MemberGroupRow
            group={group}
            expandedGroups={expandedGroups}
            setExpandedRows={setExpandedGroups}
          />
          {expandedGroups.includes(group.id) && (
            <MemberGroupProjects group={group} />
          )}
        </Box>
      ))}
    </Box>
  );
}

interface MemberGroupRowProps {
  /** Group element*/
  group: SphereDashboardAPITypes.CompanyMemberGroup;

  /** Array of IDs of the expanded group */
  expandedGroups: APITypes.GroupId[];

  /** Callback to set the expanded groups */
  setExpandedRows: React.Dispatch<React.SetStateAction<APITypes.GroupId[]>>;
}

/** Renders a single group row */
function MemberGroupRow({
  group,
  expandedGroups,
  setExpandedRows,
}: MemberGroupRowProps): JSX.Element {
  const { isScreenMdAndLarger } = useMediaQueryList();
  const { xsAndSmColumnsWidth, mdAndLargerColumnsWidth } =
    useTableColumnWidth();

  const isGroupExpanded = expandedGroups.includes(group.id);

  const options = {
    isScreenMdAndLarger,
    xsAndSmColumnsWidth,
    mdAndLargerColumnsWidth,
  };

  function handleExpandGroup(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault();
    const updatedExpandedGroups = [...expandedGroups];

    // Get the index in the expandedGroups array for clicked group
    const expandedGroupIndex = expandedGroups.indexOf(group.id);

    if (expandedGroupIndex === -1) {
      // If the index is not found it means that the clicked group is collapsed
      // Add the clicked group to expandedGroups array
      updatedExpandedGroups.push(group.id);
    } else {
      // If the index is found it means that the clicked group is expanded
      // Remove the clicked group from expandedGroups array
      updatedExpandedGroups.splice(expandedGroupIndex, 1);
    }

    setExpandedRows(updatedExpandedGroups);
  }

  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        flexFlow: isScreenMdAndLarger ? "row" : "column",
        justifyContent: isScreenMdAndLarger ? "space-between" : "normal",
        backgroundColor: gray100op50,
        "&:hover": {
          backgroundColor: sphereColors.gray100,
          cursor: "pointer",
        },
        mb: "4px",
      }}
      onClick={handleExpandGroup}
    >
      {/* Name */}
      <MemberGroupCell
        options={options}
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        <var>{group.name}</var>
      </MemberGroupCell>

      {/* Creation date */}
      <MemberGroupCell options={options}>
        <var>
          {DateTimeUtils.format({
            date: group.createdAt,
          })}
        </var>
      </MemberGroupCell>

      {/* Role */}
      <MemberGroupCell options={options}>
        {group.role ? formatUserRoleType(group.role) : "Project Access"}
      </MemberGroupCell>

      {/* Expand/collapse button */}
      <MemberGroupCell
        options={options}
        sx={{
          minWidth: "30px",
          maxWidth: "62px",
        }}
      >
        <SphereTooltip
          title={
            isGroupExpanded ? "Hide group projects" : "Show group projects"
          }
        >
          <FaroIconButton
            buttonSize={ACTION_BTN_SIZE}
            iconSize={ACTION_ICON_SIZE}
            component={isGroupExpanded ? UpArrow : DownArrow}
            onClick={handleExpandGroup}
          />
        </SphereTooltip>
      </MemberGroupCell>
    </Box>
  );
}

interface MemberGroupCellProps extends DefaultTableSizeOptions {
  /** Optional style properties  */
  sx?: SxProps;
}

/** Renders a row cell */
function MemberGroupCell({
  children,
  options,
  sx,
}: PropsWithChildren<MemberGroupCellProps>): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        alignItems: "center",
        height: options.isScreenMdAndLarger ? "70px" : "35px",
        minWidth: "70px",
        width: options.isScreenMdAndLarger
          ? "100%"
          : options.xsAndSmColumnsWidth,
        ...withEllipsis,
        padding: "8px 16px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
