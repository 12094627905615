import { CoreAPITypes } from "@stellar/api-logic";
import { sphereColors } from "@styles/common-colors";

/** All the possible status, unclassified is for when backend doesn't provide any value */
export type AllMarkupStatus = CoreAPITypes.EIssueStatus | "unclassified";

/** Different colors of the status */
export interface MarkupStatusColorReturn {
  /** The color of the icon */
  icon: string;

  /** The color of the icon's background color */
  iconBackground: string;

  /** The color of the icon's border color */
  iconBorder: string;
}

/** Contains different colors for (icon, icon-background, icon-border) based on the status */
export const markupStatusColor: Record<
  AllMarkupStatus,
  MarkupStatusColorReturn
> = {
  [CoreAPITypes.EIssueStatus.open]: {
    icon: sphereColors.gray700,
    iconBackground: sphereColors.gray100,
    iconBorder: sphereColors.gray300,
  },
  [CoreAPITypes.EIssueStatus.inProgress]: {
    icon: sphereColors.blue400,
    iconBackground: sphereColors.blue50,
    iconBorder: sphereColors.blue100,
  },
  [CoreAPITypes.EIssueStatus.toReview]: {
    icon: sphereColors.yellow650,
    iconBackground: sphereColors.yellow100,
    iconBorder: sphereColors.yellow200,
  },
  [CoreAPITypes.EIssueStatus.resolved]: {
    icon: sphereColors.green600,
    iconBackground: sphereColors.green50,
    iconBorder: sphereColors.green100,
  },
  unclassified: {
    icon: sphereColors.gray800,
    iconBackground: sphereColors.pureWhite,
    iconBorder: sphereColors.gray200,
  },
};

/** Project markup status to display in readable text */
export const projectMarkupStatusText: {
  [key in AllMarkupStatus]: string;
} = {
  [CoreAPITypes.EIssueStatus.open]: "To do",
  [CoreAPITypes.EIssueStatus.inProgress]: "In progress",
  [CoreAPITypes.EIssueStatus.toReview]: "In review",
  [CoreAPITypes.EIssueStatus.resolved]: "Resolved",
  unclassified: "Unclassified",
};

/** All the dropdown options for markup status */
export interface MarkupStatusOption {
  /** Unique key for every option */
  key: AllMarkupStatus;

  /** Value represents each key */
  value: AllMarkupStatus;

  /** Text to show for each option */
  label: string;
}

/** List of available options to use for the DropDownMarkupField's value property */
export const STATUS_OPTIONS: MarkupStatusOption[] = [
  {
    key: "unclassified",
    value: "unclassified",
    label: projectMarkupStatusText.unclassified,
  },
  {
    key: CoreAPITypes.EIssueStatus.open,
    value: CoreAPITypes.EIssueStatus.open,
    label: projectMarkupStatusText[CoreAPITypes.EIssueStatus.open],
  },
  {
    key: CoreAPITypes.EIssueStatus.inProgress,
    value: CoreAPITypes.EIssueStatus.inProgress,
    label: projectMarkupStatusText[CoreAPITypes.EIssueStatus.inProgress],
  },
  {
    key: CoreAPITypes.EIssueStatus.toReview,
    value: CoreAPITypes.EIssueStatus.toReview,
    label: projectMarkupStatusText[CoreAPITypes.EIssueStatus.toReview],
  },
  {
    key: CoreAPITypes.EIssueStatus.resolved,
    value: CoreAPITypes.EIssueStatus.resolved,
    label: projectMarkupStatusText[CoreAPITypes.EIssueStatus.resolved],
  },
];
