interface Props {
  /** ULM URL that will be the source for the iFrame */
  iFrameUrl: string | undefined;
}

/** Unified Login Mask embedded in an iFrame */
export function Ulm({ iFrameUrl }: Props): JSX.Element {
  return (
    <iframe
      title="unified-login-mask"
      id="data-testid-ULM-IFRAME"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        border: "none",
      }}
      src={iFrameUrl}
    />
  );
}
