import { sphereColors } from "@styles/common-colors";
import { BaseMemberProps } from "@custom-types/member-types";
import { getMemberStatus, isMemberActive } from "@utils/user-utils";
import { SphereStatusChip } from "@components/common/sphere-status-chip";
import { useMemo } from "react";

/**
 * Returns the registration status of a team member as a chip
 * If the identity is equal to id, then the user is registered and active
 */
export function MemberStatus({ member }: BaseMemberProps): JSX.Element {
  const statusChipProps = useMemo(() => {
    return isMemberActive(member)
      ? {
          label: getMemberStatus({ member }),
          color: sphereColors.blue500,
          backgroundColor: sphereColors.blue100,
          tooltip: "The invited member successfully created an account",
        }
      : {
          label: getMemberStatus({ member }),
          color: sphereColors.yellow650,
          backgroundColor: sphereColors.yellow100,
          tooltip: "The invited member has not yet created an account",
        };
  }, [member]);

  return <SphereStatusChip {...statusChipProps} />;
}
