import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { runtimeConfig } from "@src/runtime-config";
import {
  IFrameMessage,
  getLoginUrl,
  isRedirectMessage,
  isUserAccountMessage,
  isUserStatusMessage,
} from "@context-providers/auth/use-auth-user-module-utils";
import { useLoadingSpinner } from "@context-providers/loading-spinner-provider";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";

const userModuleUrl = runtimeConfig.urls.userModuleUrl;

interface Props {
  /** Callback function when the user module has carried out a login check */
  loginCheckCallback: (isUserLoggedIn: boolean) => void;

  /** Callback function when the user module has successfully logged out the user */
  successfulLogoutCallback(): void;
}

interface UseAuthUserModule {
  /** iFrame URL */
  iFrameUrl: string | undefined;

  /** Function to set the iFrame URL */
  setIFrameUrl: Dispatch<SetStateAction<string | undefined>>;
}

/** Custom hook that handles an instance of the user module */
export function useAuthUserModule({
  loginCheckCallback,
  successfulLogoutCallback,
}: Props): UseAuthUserModule {
  const { handleErrorWithPage } = useErrorContext();
  const [iFrameUrl, setIFrameUrl] = useState<string | undefined>(
    () =>
      // Initialize with the login url so we receive the window event with the login state
      // Check if the loginEmail param was passed. Example: from the unified login mask
      getLoginUrl({
        userModuleUrl,
        shouldGetLoginEmailParam: true,
        handleError: handleErrorWithPage,
      })?.href
  );

  // Memoize callback to ensure it does not trigger unnecessary re-renders.
  const memoizedLoginCheckCallback = useCallback(
    (isUserLoggedIn: boolean) => {
      loginCheckCallback(isUserLoggedIn);
    },
    [loginCheckCallback]
  );

  // Memoize callback to ensure it does not trigger unnecessary re-renders.
  const memoizedSuccessfulLogoutCallback = useCallback(() => {
    successfulLogoutCallback();
  }, [successfulLogoutCallback]);

  const { setLoadingSpinner } = useLoadingSpinner();

  // If the iFrameUrl is undefined then stop any active loading spinner so the modal dialog is visible
  useEffect(() => {
    if (!iFrameUrl) {
      setLoadingSpinner(false);
    }
  }, [iFrameUrl, setLoadingSpinner]);

  // Add event listener for messages coming from the UserModule
  useEffect(() => {
    function onWindowMessage(message: MessageEvent<IFrameMessage>): void {
      // Early exit if the window message does not come from the user module
      if (message.origin !== new URL(userModuleUrl).origin) {
        return;
      }

      if (isUserStatusMessage(message.data)) {
        if (message.data.isUserLoggedIn !== undefined) {
          memoizedLoginCheckCallback(message.data.isUserLoggedIn);
        }

        if (message.data.isUserLoggedOut) {
          memoizedSuccessfulLogoutCallback();
        }
      } else if (isRedirectMessage(message.data)) {
        window.location.replace(message.data.redirect);
      } else if (isUserAccountMessage(message.data)) {
        if (message.data.isUserAccountIncomplete === true) {
          // Hide any active loading spinner to make the User Module visible
          setLoadingSpinner(false);
        }
      }
    }

    window.addEventListener("message", onWindowMessage);

    // Cleanup. Remove event listener before the component is unmounted or re-rendered.
    return () => window.removeEventListener("message", onWindowMessage);
  }, [
    memoizedLoginCheckCallback,
    memoizedSuccessfulLogoutCallback,
    setLoadingSpinner,
  ]);

  return { iFrameUrl, setIFrameUrl };
}
