import {
  CreateDialogProps,
  useDialog,
} from "@components/common/dialog/dialog-provider";
import { FARO_CARD_ACTIONS } from "@components/common/faro-card/faro-card";
import { FaroIconButton } from "@components/common/faro-icon-button";
import {
  ACTION_BTN_SIZE,
  ACTION_ICON_SIZE,
} from "@components/common/project-actions";
import { SphereShareLink } from "@components/common/sphere-share-link";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { OpenProjectTarget } from "@custom-types/sdb-company-types";
import { Box } from "@mui/material";
import { PointCloudEvents } from "@utils/track-event/track-event-list";
import {
  OPEN_OTHERS_TARGET_ATTRIBUTE,
  OpenProjectProps,
  trackOpenProject,
} from "@utils/project-utils";
import { MouseEvent, useCallback } from "react";
import ShareIcon from "@assets/icons/Share.svg?react";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import OpenViewerIcon from "@assets/icons/new/open-in-viewer.svg?react";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/**
 * Dialog provider props to use for the share annotation dialog.
 */
export const SHARE_POINT_CLOUD_DIALOG_PROVIDER_PROPS: CreateDialogProps = {
  title: "Share point cloud",
  maxWidth: "sm",
  shouldHideActions: true,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
  fullWidth: true,
};

interface Props {
  /** URL of the Viewer deep-link to open/share the point cloud */
  sphereViewerUrl: string;

  /** Show the divider */
  isDividerVisible?: boolean;
}

/** Component that renders the action buttons column for the point clouds table */
export function PointCloudActions({
  sphereViewerUrl,
  isDividerVisible = true,
}: Props): JSX.Element {
  const { createDialog } = useDialog();
  const { trackEvent, trackAsyncEvent } = useTrackEvent();

  /**
   * Opens the point cloud in the Viewer and tracks the event
   */
  function onOpenPointCloud(
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    clickType: OpenProjectProps["clickType"]
  ): void {
    event.stopPropagation();

    trackOpenProject({
      openFrom: "projectDetails-data-pointClouds",
      clickType,
      numberOfMembers: undefined,
      openTarget: OpenProjectTarget.sphereViewer,
      trackAsyncEvent,
    });
  }

  /** Triggered to share a markup by deep-link */
  const onSharePointCloud = useCallback((): void => {
    trackEvent({ name: PointCloudEvents.sharePointCloud });

    createDialog(
      SHARE_POINT_CLOUD_DIALOG_PROVIDER_PROPS,
      <SphereShareLink title="Point cloud link" url={sphereViewerUrl} />
    );
  }, [createDialog, sphereViewerUrl, trackEvent]);

  return (
    <Box
      component="div"
      className={FARO_CARD_ACTIONS}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <SphereTooltip title="Share point cloud">
        <FaroIconButton
          buttonSize={ACTION_BTN_SIZE}
          iconSize={ACTION_ICON_SIZE}
          component={ShareIcon}
          onClick={onSharePointCloud}
        />
      </SphereTooltip>

      {isDividerVisible && <SphereActionDivider />}

      <SphereTooltip title="Open point cloud">
        <FaroIconButton
          buttonSize={ACTION_BTN_SIZE}
          iconSize={ACTION_ICON_SIZE}
          component={OpenViewerIcon}
          href={sphereViewerUrl}
          target={OPEN_OTHERS_TARGET_ATTRIBUTE}
          onClick={(event) => onOpenPointCloud(event, "left button click")}
          onAuxClick={(event) => onOpenPointCloud(event, "middle button click")}
          onContextMenu={(event) =>
            onOpenPointCloud(event, "open context menu")
          }
        />
      </SphereTooltip>
    </Box>
  );
}
