import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { getMarkupsAttachmentFilterOptions } from "@pages/project-details/project-markups/project-markups-filters/project-markups-filter-list";
import { FilterTableEvents } from "@utils/track-event/track-event-list";
import { SingleFilterChip } from "@components/common/faro-table/faro-table-filter/single-filter-chip";

/** Show filters and apply them for project markup Attachment */
export function ProjectMarkupsAttachmentFilter(): JSX.Element {
  return (
    <SingleFilterChip
      filteredColumn={ProjectMarkupsTableHeaders.attachments}
      label={"Has linked attachments"}
      item={getMarkupsAttachmentFilterOptions()}
      filterTableType={FilterTableEvents.filterAnnotations}
    />
  );
}
