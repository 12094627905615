import { FaroTextButton } from "@components/common/faro-text-button";
import { Divider } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { isAnyFilterAppliedInCurrentTableSelector } from "@store/table/table-selector";
import { resetFilterFromTable } from "@store/table/table-slice";

/** The reset all button and its functionality */
export function FilterToolbarResetAll(): JSX.Element | null {
  const isAnyFilterApplied = useAppSelector(
    isAnyFilterAppliedInCurrentTableSelector
  );
  const dispatch = useAppDispatch();

  if (!isAnyFilterApplied) {
    return null;
  }

  return (
    <>
      {isAnyFilterApplied && <Divider orientation="vertical" />}
      <FaroTextButton
        onClick={() => dispatch(resetFilterFromTable())}
        tooltipText="Clear all filters"
        sx={{ fontWeight: 600 }}
      >
        Clear filters
      </FaroTextButton>
    </>
  );
}
