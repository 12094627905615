import { Markup } from "@custom-types/project-markups-types";
import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { getMarkupStatusValue } from "@pages/project-details/project-markups/status/markup-status-utils";
import { useCallback } from "react";

/** Get the value entity of markup based on the header */
export function useProjectMarkupFilterUtils(): (
  header: ProjectMarkupsTableHeaders
) => (markup: Markup) => string | string[] | number | number[] | undefined {
  return useCallback((header: ProjectMarkupsTableHeaders) => {
    switch (header) {
      case ProjectMarkupsTableHeaders.name:
        return (markup: Markup) => markup.name;

      case ProjectMarkupsTableHeaders.status:
        return (markup: Markup) => getMarkupStatusValue(markup.status?.value);

      case ProjectMarkupsTableHeaders.assignee:
        return (markup: Markup) => markup.assignee?.values?.[0];

      case ProjectMarkupsTableHeaders.dueDate:
        return (markup: Markup) => {
          if (markup.dueDate) {
            return new Date(markup.dueDate.value).getTime();
          }
          return undefined;
        };

      case ProjectMarkupsTableHeaders.tags:
        return (markup: Markup) => {
          if (markup.labels && markup.labels.length !== 0) {
            return markup.labels.map((label) => label.id) ?? undefined;
          }
          return undefined;
        };

      case ProjectMarkupsTableHeaders.sheet:
        return (markup: Markup) => markup.areaSection?.id;

      case ProjectMarkupsTableHeaders.scene:
        return (markup: Markup) => markup.section?.id;

      case ProjectMarkupsTableHeaders.attachments:
        return (markup: Markup) => markup.attachments.length;

      default:
        return () => undefined;
    }
  }, []);
}
