import { PasswordErrors } from "@components/common/change-password/change-password-utils";
import { Box, Stack } from "@mui/material";
import CheckIcon from "@assets/icons/new/check-circle_18px.svg?react";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** Error status of the password validation rules */
  errors: PasswordErrors;
}

/** A components that shows the error status of each password validation rule */
export function PasswordCheck({ errors }: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        mt: "4px",
        height: "180px",
        padding: "16px",
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between",
        backgroundColor: sphereColors.gray50,
      }}
    >
      <PasswordCheckItem
        text="14 characters minimum"
        isError={errors.isMinCharLengthError}
      />

      <PasswordCheckItem
        text="1 uppercase character"
        isError={errors.isUppercaseLetterError}
      />

      <PasswordCheckItem
        text="1 lowercase character"
        isError={errors.isLowercaseLetterError}
      />

      <PasswordCheckItem text="1 number" isError={errors.isNumberError} />

      <PasswordCheckItem
        text="1 special character (!@#%$&)"
        isError={errors.isSpecialCharError}
      />

      <PasswordCheckItem
        text="No leading or trailing white spaces"
        isError={errors.isLeadingOrTrailingSpaceError}
      />

      <PasswordCheckItem
        text="Must not contain email address"
        isError={errors.isContainsEmailError}
      />
    </Box>
  );
}

interface PasswordCheckItemProps {
  /** Display text for each rule */
  text: string;

  /** Errors status */
  isError: boolean;
}

/** Renders the status of a single password validation rule */
function PasswordCheckItem({
  text,
  isError,
}: PasswordCheckItemProps): JSX.Element {
  return (
    <Stack
      sx={{
        display: "flex",
        flexFlow: "row",
      }}
    >
      <CheckIcon
        color={isError ? sphereColors.gray300 : sphereColors.green700}
      />
      <Box component="div" ml="10px">
        {text}
      </Box>
    </Stack>
  );
}
