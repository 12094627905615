import { CSSProperties } from "react";
// We use the MUI TextField component because the FaroTextField component has some things
// that we don't need like buttons to save and has some styling that we don't need.
// eslint-disable-next-line no-restricted-imports -- Needed to use the MUI TextField component
import { Box, TextField } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { FaroButton } from "@components/common/faro-button";
import { copyToClipboard } from "@utils/ui-utils";
import { useToast } from "@hooks/use-toast";
import { SphereLabel } from "@components/common/sphere-label";

const INPUT_BORDER: CSSProperties["border"] = `1px solid ${sphereColors.gray500}`;

interface Props {
  /** Text to show as title of the dialog */
  title: string;

  /** URL to share */
  url: string;
}

/**
 * Renders a dialog to share a URL.
 * It has a text field that shows the URL and a button to copy it to the clipboard.
 */
export function SphereShareLink({ title, url }: Props): JSX.Element {
  const { showToast } = useToast();

  /** Copies the URL to the clipboard */
  function copyUrl(): void {
    copyToClipboard(url);

    showToast({
      message: "Link copied",
      type: "success",
    });
  }

  return (
    <Box component="div">
      <SphereLabel title={title} />
      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <TextField
          value={url}
          size="small"
          sx={{
            height: "37px",
            width: "100%",
            "& .MuiInputBase-input": {
              color: sphereColors.gray800,
              fontSize: "14px",
              textOverflow: "ellipsis",
            },
            marginRight: "10px",
            "&:focus-visible": {
              outline: "none",
            },
            "& .MuiInputBase-root": {
              borderTopRightRadius: "0px",
              borderBottomRightRadius: "0px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: `${INPUT_BORDER} !important`,
              },
              "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                  border: INPUT_BORDER,
                },
              },
            },
          }}
        />
        <FaroButton
          sx={{
            marginLeft: "-10px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            height: "37px",
            minWidth: "80px",
          }}
          onClick={copyUrl}
        >
          Copy
        </FaroButton>
      </Box>
    </Box>
  );
}
