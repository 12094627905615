import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { DropdownFilterChip } from "@components/common/faro-table/faro-table-filter/dropdown-filter-chip";
import { FilterTableEvents } from "@utils/track-event/track-event-list";
import { getMarkupsStatusFilterOptions } from "@pages/project-details/project-markups/project-markups-filters/project-markups-filter-list";

/** Show filters and apply them for project markup status */
export function ProjectMarkupsStatusFilter(): JSX.Element {
  return (
    <DropdownFilterChip
      label={ProjectMarkupsTableHeaders.status}
      filteredColumn={ProjectMarkupsTableHeaders.status}
      items={getMarkupsStatusFilterOptions()}
      shouldShowSearchBox={false}
      shouldShowCheckbox={true}
      filterTableType={FilterTableEvents.filterAnnotations}
    />
  );
}
