import { BackgroundTask } from "@api/progress-api/progress-api-types";
import { GUID } from "@faro-lotv/foundation";
import { BackgroundTaskState } from "@faro-lotv/service-wires";
import { APITypes } from "@stellar/api-logic";

/** All the properties returned after upload a file */
export interface UploadedFile {
  /** The id of the upload task */
  id: GUID;

  /** The name of the file */
  fileName: string;

  /** The size of the file */
  fileSize: number;

  /** The type of the file */
  fileType: string;

  /** The downloadable url of the file */
  downloadUrl: string;

  /** Md5Hash of the file */
  md5: string;
}
/** All the properties returned after a failed upload  */
export interface UploadFailedFile {
  /** The id of the upload task */
  id: GUID;

  /** The name of the file */
  fileName: string;

  /** The error message reasoning the fail */
  errorMessage: string;
}

export type UploadedFileResponse = UploadedFile | UploadFailedFile;

/**
 * Used to determine the usage of the file upload by upload manager
 */
export enum UploadElementType {
  /** Default usage to upload files without specifying a project */
  default = "default",

  /** Upload files to a project */
  project = "project",

  /** Upload files for a project annotation */
  annotation = "annotation",

  /** Upload ELS scan data for a project */
  elsScan = "elsScan",
}

/** Base context for a file upload task */
interface BaseFileUploadTaskContext {
  /** Usage type of the file */
  uploadElementType: UploadElementType;

  /** ID of the project associated to the file upload */
  projectId?: APITypes.ProjectId;
}

/** Default context for a file upload task */
interface DefaultFileUploadTaskContext extends BaseFileUploadTaskContext {
  uploadElementType: UploadElementType.default;
}

/** Base context for a file upload task targeted to an specific project */
interface BaseProjectFileUploadTaskContext extends BaseFileUploadTaskContext {
  /** ID of the project associated to the file upload */
  projectId: APITypes.ProjectId;
}

/** Context for a file upload task targeted to an specific project */
interface ProjectFileUploadTaskContext
  extends BaseProjectFileUploadTaskContext {
  uploadElementType: UploadElementType.project;
}

/** Context for a file upload task targeted to an specific annotation of a project */
interface AnnotationFileUploadTaskContext
  extends BaseProjectFileUploadTaskContext {
  uploadElementType: UploadElementType.annotation;

  /** ID of the markup (annotation) iElement associated to the file upload */
  iElementId: GUID;
}

/** Context for a file upload task targeted to an specific capture tree revision of a project */
export interface ElsScanFileUploadTaskContext
  extends BaseProjectFileUploadTaskContext {
  uploadElementType: UploadElementType.elsScan;

  /** ID of the capture tree revision where the scans will be added */
  registrationRevisionId: string;

  /** ID of the capture tree revision cluster entity where the scans will be added */
  revisionClusterEntityId: string;
}

export type FileUploadTaskContext =
  | DefaultFileUploadTaskContext
  | ProjectFileUploadTaskContext
  | AnnotationFileUploadTaskContext
  | ElsScanFileUploadTaskContext;

/** A task to track the upload of a file */
export interface FileUploadTask
  extends Omit<BackgroundTask, "status" | "taskType" | "context" | "tags"> {
  /** Name of the file */
  fileName: string;

  /** Whether progress toast notifications should be hidden */
  isSilent?: boolean;

  /** Current status of a task */
  status: BackgroundTaskState;

  /** Current progress amount of a task */
  progress: number;

  /** Expected end of task to show in ms from epoch (so it can be compared to Date.now()) */
  expectedEnd?: number;

  /** The error message if there is in a task */
  errorMessage?: string;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}

/** Necessary properties to upload a file */
export interface UploadFileParams {
  /** The file to upload */
  file: File;

  /** Callback function triggered when file upload begins */
  onUploadStart(): void;

  /** Callback function for retrieving the upload progress */
  onUploadProgress(progressEvent: ProgressEvent | number): void;

  /** Callback function triggered when file upload ends */
  onUploadComplete(fileUrl: string, context: FileUploadTaskContext): void;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}

/** Necessary properties to upload multiple file */
export interface UploadMultipleFilesParams
  extends Pick<UploadFileParams, "onUploadStart" | "onUploadProgress"> {
  /** List of files to upload */
  files: File[];

  /** Callback function triggered when file upload ends */
  onUploadComplete(
    uploadedResponse: UploadedFileResponse[],
    context: FileUploadTaskContext
  ): void;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}

/** All the params to validate file and to add failed upload task */
export interface ValidateFile {
  /** The file to validate */
  file: File;

  /** List of allowed extensions */
  allowedExtensions: string[];

  /** The maximum file size to validate */
  maxFileSize: number;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}

/** All the params to add a failed task in store while validate file */
export interface InvalidUploadToStore {
  /** The name of the file */
  fileName: string;

  /** Validation error message */
  errorMessage: string;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;
}
