import { SphereDropzone } from "@components/common/sphere-dropzone/sphere-dropzone";
import { UploadElementType } from "@custom-types/file-upload-types";
import { useBrandingSettings } from "@hooks/use-branding-settings";
import { isString } from "lodash";
import { useState } from "react";

/** Renders the workspace logo settings */
export function WorkspaceLogoSetting(): JSX.Element {
  const { brandingSettings, onChangeBrandingSettings } = useBrandingSettings();
  const [isUploadingLogo, setIsUploadingLogo] = useState<boolean>(false);

  async function onUploadWorkspaceLogo(newImageUrl: string): Promise<void> {
    await onChangeBrandingSettings(newImageUrl, "url");

    setIsUploadingLogo(false);
  }

  async function removeWorkspaceLogo(): Promise<void> {
    await onChangeBrandingSettings("", "url");
  }

  return (
    <SphereDropzone
      existingImageUrl={brandingSettings.logo.url}
      isLoading={isUploadingLogo}
      setIsLoading={setIsUploadingLogo}
      onUploadComplete={(uploadedResponse) => {
        if (isString(uploadedResponse)) {
          onUploadWorkspaceLogo(uploadedResponse);
        }
      }}
      onDeleteButtonClick={removeWorkspaceLogo}
      deleteIconTooltipText="Remove workspace logo"
      context={{
        uploadElementType: UploadElementType.default,
      }}
    />
  );
}
