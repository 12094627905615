import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { DropdownFilterChip } from "@components/common/faro-table/faro-table-filter/dropdown-filter-chip";
import { markupsUniqueKeysSelector } from "@store/markups/markups-selector";
import { useAppSelector } from "@store/store-helper";
import { getMarkupsTagsFilterOptions } from "@pages/project-details/project-markups/project-markups-filters/project-markups-filter-list";
import { useMemo } from "react";
import { FilterTableEvents } from "@utils/track-event/track-event-list";

/** Show filters and apply them for project markup tags */
export function ProjectMarkupsTagsFilter(): JSX.Element {
  const markupsLabels = useAppSelector(markupsUniqueKeysSelector("labels"));
  const allFilterTags = useMemo(
    () => getMarkupsTagsFilterOptions(markupsLabels),
    [markupsLabels]
  );

  return (
    <DropdownFilterChip
      label={ProjectMarkupsTableHeaders.tags}
      filteredColumn={ProjectMarkupsTableHeaders.tags}
      items={allFilterTags}
      shouldShowSearchBox={true}
      shouldShowCheckbox={true}
      filterTableType={FilterTableEvents.filterAnnotations}
      size="large"
    />
  );
}
