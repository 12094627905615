import { SphereStatusChip } from "@components/common/sphere-status-chip";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { useMemo } from "react";
import {
  getMarkupStatusColor,
  getMarkupStatusLabel,
} from "@pages/project-details/project-markups/status/markup-status-utils";

/** Renders the status of a project markup as a chip */
export function MarkupStatus({ markup }: BaseMarkupProps): JSX.Element {
  const status = markup.status?.value;

  /** Color of the status icon */
  const markupStatusColor = useMemo(() => {
    return getMarkupStatusColor(status);
  }, [status]);

  /** Label to show mark status */
  const label = useMemo(() => {
    return getMarkupStatusLabel(status);
  }, [status]);

  return (
    <SphereStatusChip
      label={label}
      color={markupStatusColor.icon}
      backgroundColor={markupStatusColor.iconBackground}
      variant={status ? "filled" : "outlined"}
      styleChip={{ cursor: "pointer" }}
    />
  );
}
