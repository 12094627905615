import { TableType } from "@utils/track-event/track-event-list";
import { useGetFilterLogicsForColumn } from "@hooks/table-filters/use-get-filter-logics-for-column";
import { useMemo } from "react";
import {
  EntityForTableType,
  HeaderForTableType,
} from "@components/common/faro-table/faro-table-types";
import { ProjectMarkupsTableHeaders } from "@pages/project-details/project-markups/project-markups-table-utils";
import { useAppSelector } from "@store/store-helper";
import { quickFilterSelector } from "@store/table/table-selector";

interface Props<Table, Entity, Column> {
  /** The type of table */
  tableType: Table;

  /** The entities to apply the filters */
  entities: Entity[];

  /** Columns that filter needs to be applied */
  columns: Column[];

  /** The column that will be used for quick search */
  quickSearchColumn?: Column;

  /** Returns the value of entity that represent in the header */
  getEntityForColumn: (
    column: Column
  ) => (entity: Entity) => string | string[] | number | number[] | undefined;
}

/** Apply all the filters to the entities and return the ones that pass */
export function useApplyFilters<
  Table extends TableType,
  Entity extends EntityForTableType<Table>,
  Column extends HeaderForTableType<Table>
>({
  tableType,
  entities,
  columns,
  quickSearchColumn,
  getEntityForColumn,
}: Props<Table, Entity, Column>): Entity[] {
  const hasEntityPassFiltersForColumn = useGetFilterLogicsForColumn(tableType);
  const quickFilter = useAppSelector(quickFilterSelector);
  const quickFilterLowerCase = quickFilter.toLowerCase();

  const filteredEntities = useMemo(
    () =>
      entities.filter((entity) => {
        const isAnyFilterFailedForAColumn = columns.some((column) => {
          const columnValue = getEntityForColumn(column)(entity);

          if (column === quickSearchColumn && typeof columnValue === "string") {
            return (
              columnValue.toLowerCase().includes(quickFilterLowerCase) === false
            );
          }

          return (
            hasEntityPassFiltersForColumn(
              columnValue,
              column as ProjectMarkupsTableHeaders
            ) === false
          );
        });

        // If any filter in any column fails, then the entity should not be shown
        return !isAnyFilterFailedForAColumn;
      }),
    [
      columns,
      entities,
      getEntityForColumn,
      hasEntityPassFiltersForColumn,
      quickFilterLowerCase,
      quickSearchColumn,
    ]
  );

  return filteredEntities;
}
