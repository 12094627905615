import {
  EntityAdapter,
  EntityId,
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { BaseEntityState } from "@store/store-types";
import { TableType } from "@utils/track-event/track-event-list";
import {
  BulkActionResults,
  FetchingItem,
  FetchingStatus,
  FilterPayload,
} from "@store/table/table-slice-helper";
import { BulkName } from "@components/common/faro-table/faro-table-types";
import {
  FilterColumnNames,
  FilterOption,
} from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { isEmpty } from "lodash";

/** State of the current table logic */
export interface TableState extends BaseEntityState<FetchingItem> {
  /** The table that the user is currently checking */
  tableType: TableType | null;

  /** The name of the bulk action the user has selected */
  bulkActionName: BulkName | null;

  /** Results after bulk actions */
  bulkActionResults: BulkActionResults;

  /** All the bulk action buttons that is allowed in a session based on initial selected entities */
  allowedBulkButtonsInSession: BulkName[];

  /** All the filtered columns and their filtered attribute */
  filters:
    | {
        [key in TableType]?: { [key in FilterColumnNames]?: FilterOption[] };
      };

  /** The searched text for filtering */
  quickFilter: string;
}

/** Creates an entity adapter to store a map with all the table rows that are going to be updated */
export const TableAdapter: EntityAdapter<FetchingItem> = createEntityAdapter();

const initialState: TableState = {
  ...TableAdapter.getInitialState(),
  tableType: null,
  bulkActionName: null,
  bulkActionResults: { numberOfErrors: 0, numberOfSuccess: 0 },
  allowedBulkButtonsInSession: [],
  filters: {},
  quickFilter: "",
};

/** Slice to access state of table */
const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    /** Remove single entity based on the provided ID before fetching and manually */
    removeOneManually(state, action: PayloadAction<EntityId>) {
      TableAdapter.removeOne(state, action.payload);

      if (state.ids.length === 0) {
        state.bulkActionName = null;
        state.bulkActionResults = { numberOfErrors: 0, numberOfSuccess: 0 };
      }
    },

    /** Remove single entity based on the provided ID after fetching done */
    removeOneAfterFetching(state, action: PayloadAction<EntityId>) {
      TableAdapter.removeOne(state, action.payload);
    },

    /** Update single entity based on the provided ID and changes */
    updateOne: TableAdapter.updateOne,

    /** Set the initial fetching items */
    setInitialFetchingItems(state, action: PayloadAction<EntityId[]>) {
      TableAdapter.setAll(
        state,
        action.payload.map((id) => {
          return {
            id: id as string,
            status: "idle",
            message: "",
          };
        })
      );
    },

    /**
     * Change the status of all the provided items if the status is not "not-allowed".
     * It will also clear the message if requested.
     */
    changeAllFetchingStatus(
      state,
      action: PayloadAction<{
        status: FetchingStatus;
        shouldClearMessage: boolean;
      }>
    ) {
      TableAdapter.setAll(
        state,
        TableAdapter.getSelectors()
          .selectAll(state)
          .map((item) => {
            return {
              ...item,
              status:
                item.status !== "not-allowed"
                  ? action.payload.status
                  : item.status,
              message: action.payload.shouldClearMessage ? "" : item.message,
            };
          })
      );
    },

    setTableType(state, action: PayloadAction<TableType | null>) {
      state.tableType = action.payload;
    },

    setBulkActionResults(state, action: PayloadAction<BulkActionResults>) {
      state.bulkActionResults = action.payload;
    },

    /** Set the allowed bulk actions */
    setAllowedBulkButtonsInSession(state, action: PayloadAction<BulkName[]>) {
      state.allowedBulkButtonsInSession = action.payload;
    },

    setBulkActionName(state, action: PayloadAction<BulkName | null>) {
      if (action.payload === null) {
        state.bulkActionName = null;
        state.allowedBulkButtonsInSession = [];
        state.bulkActionResults = { numberOfErrors: 0, numberOfSuccess: 0 };
        TableAdapter.setAll(
          state,
          TableAdapter.getSelectors()
            .selectAll(state)
            .map((item) => {
              return { ...item, status: "idle", message: "" };
            })
        );
      } else {
        state.bulkActionName = action.payload;
      }
    },

    /** Update filters in table by adding or removing it from the  provided column */
    updateFiltersInColumn(state, action: PayloadAction<FilterPayload>) {
      // Remove the icon from the filter option as it is not needed in the filter
      delete action.payload.filter.icon;
      const tableType = state.tableType;
      if (tableType === null) {
        return;
      }

      if (action.payload.shouldReplaceFilterInColumn) {
        state.filters = {
          ...state.filters,
          [tableType]: {
            ...state.filters?.[tableType],
            [action.payload.filteredColumn]: [action.payload.filter],
          },
        };
        return;
      }

      const existingFilterItemsForSelectedColumn =
        state.filters?.[tableType]?.[action.payload.filteredColumn] ?? [];
      const indexOfNewFilter = existingFilterItemsForSelectedColumn.findIndex(
        (existingFilter) => existingFilter.id === action.payload.filter.id
      );

      if (indexOfNewFilter === -1) {
        existingFilterItemsForSelectedColumn.push(action.payload.filter);
      } else {
        existingFilterItemsForSelectedColumn.splice(indexOfNewFilter, 1);
      }

      state.filters = {
        ...state.filters,
        [tableType]: {
          ...state.filters?.[tableType],
          [action.payload.filteredColumn]: existingFilterItemsForSelectedColumn,
        },
      };

      // If no filters are left, remove the column preventing empty arrays being treated in the filter logics
      if (existingFilterItemsForSelectedColumn.length === 0) {
        delete state.filters?.[tableType]?.[action.payload.filteredColumn];
      }

      // Remove the filter table type if no filters are left for the table
      if (isEmpty(state.filters?.[tableType])) {
        delete state.filters?.[tableType];
      }
    },

    /** Set the quick filter text */
    setQuickFilter(state, action: PayloadAction<string>) {
      state.quickFilter = action.payload;
    },

    /** Only reset the logics related to filtering table */
    resetFilterFromTable(state) {
      state.filters = {};
      state.quickFilter = "";
    },

    /** Reset the whole table slice */
    resetTableState: () => initialState,
  },
});

export const {
  updateOne,
  removeOneManually,
  removeOneAfterFetching,
  setInitialFetchingItems,
  setTableType,
  setBulkActionName,
  setBulkActionResults,
  changeAllFetchingStatus,
  setAllowedBulkButtonsInSession,
  updateFiltersInColumn,
  setQuickFilter,
  resetFilterFromTable,
  resetTableState,
} = tableSlice.actions;

export const tableReducer = tableSlice.reducer;
