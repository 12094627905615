import { Stack } from "@mui/material";
import { FilterToolbarQuickSearch } from "@components/common/faro-table/faro-table-filter/filter-toolbar-quick-search";
import { FilterToolbarResetAll } from "@components/common/faro-table/faro-table-filter/filter-toolbar-reset-all";
import { FilterToolbarTitle } from "@components/common/faro-table/faro-table-filter/filter-toolbar-title";

interface Props {
  /** filter chips shown in filter toolbar */
  filterChipComponents: JSX.Element[];
}

/** Toolbar to show for filter chips in large screens */
export function FilterToolbarLargeScreen({
  filterChipComponents,
}: Props): JSX.Element {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack
        direction="row"
        justifyContent={"flex-start"}
        sx={{
          height: "28px",
          alignItems: "center",
          gap: "8px",
          my: "17px",
        }}
      >
        <FilterToolbarTitle />
        {filterChipComponents}
        <FilterToolbarResetAll />
      </Stack>
      <FilterToolbarQuickSearch />
    </Stack>
  );
}
