import { EntityId, createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import { TableAdapter } from "@store/table/table-slice";
import { companyMemberSelector } from "@store/members/members-selector";
import { MemberTypes } from "@custom-types/member-types";
import {
  BulkActionResults,
  FetchingItem,
  FaroFilterModel,
} from "@store/table/table-slice-helper";
import { TableType } from "@utils/track-event/track-event-list";
import { SdbProject } from "@custom-types/project-types";
import { getProjectByIdSelector } from "@store/projects/projects-selector";
import { GroupTypes } from "@custom-types/group-types";
import { getGroupByIdSelector } from "@store/groups/groups-selector";
import {
  TableDataType,
  BulkName,
} from "@components/common/faro-table/faro-table-types";
import { PointCloud } from "@custom-types/point-cloud-types";
import { getPointCloudByIdSelector } from "@store/point-clouds/point-clouds-selector";
import { Markup } from "@custom-types/project-markups-types";
import { getMarkupByIdSelector } from "@store/markups/markups-selector";
import {
  FilterColumnNames,
  FilterOption,
} from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";

/** Returns the bulk action table type */
export const tableTypeSelector: (state: RootState) => TableType | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.table.tableType;
    }
  );

/** Returns the bulk action name */
export const bulkActionNameSelector: (state: RootState) => BulkName | null =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.table.bulkActionName;
    }
  );

/** Returns the bulk action selected row Ids */
export const selectedRowIdsSelector: (state: RootState) => EntityId[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return TableAdapter.getSelectors().selectIds(state.table);
    }
  );

/** Returns the bulk action results */
export const bulkActionResultsSelector: (
  state: RootState
) => BulkActionResults = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.table.bulkActionResults;
  }
);

/** Returns all updating items */
export const updatingItemsSelector: (state: RootState) => FetchingItem[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const allProjects = TableAdapter.getSelectors().selectAll(state.table);

      return allProjects;
    }
  );

/** Returns the total number of selected items */
export const numberOfUpdatingItemsSelector: (state: RootState) => number =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const allProjects = TableAdapter.getSelectors().selectTotal(state.table);

      return allProjects;
    }
  );

// All the possible types to use in the selectedEntitiesSelector
export function selectedEntitiesSelector(
  bulkActionEntityType: "members"
): (state: RootState) => MemberTypes[];
export function selectedEntitiesSelector(
  bulkActionEntityType: "projects"
): (state: RootState) => SdbProject[];
export function selectedEntitiesSelector(
  bulkActionEntityType: "groups"
): (state: RootState) => GroupTypes[];
export function selectedEntitiesSelector(
  bulkActionEntityType: "pointClouds"
): (state: RootState) => PointCloud[];
export function selectedEntitiesSelector(
  bulkActionEntityType: "markups"
): (state: RootState) => Markup[];

/** Receive entity type and return all the selected items in table  */
export function selectedEntitiesSelector(
  bulkActionEntityType: TableDataType
): (
  state: RootState
) => MemberTypes[] | SdbProject[] | GroupTypes[] | PointCloud[] | Markup[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      switch (bulkActionEntityType) {
        case "projects":
          return selectedProjectEntitiesSelector(state);
        case "members":
          return selectedMemberEntitiesSelector(state);
        case "groups":
          return selectedGroupEntitiesSelector(state);
        case "pointClouds":
          return selectedPointCloudEntitiesSelector(state);
        case "markups":
          return selectedMarkupEntitiesSelector(state);

        default:
          throw new Error(`Unknown context type: ${bulkActionEntityType}`);
      }
    }
  );
}

/** Return all the member entities based on the updating items ID */
const selectedMemberEntitiesSelector: (state: RootState) => MemberTypes[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedRowIds = selectedRowIdsSelector(state);

      return selectedRowIds.reduce<MemberTypes[]>(
        (selectedEntities, selectedRow) => {
          const companyMember = companyMemberSelector(selectedRow.toString())(
            state
          );

          if (companyMember !== null) {
            selectedEntities.push(companyMember);
          }
          return selectedEntities;
        },
        []
      );
    }
  );

/** Return all the project entities based on the updating items ID */
const selectedProjectEntitiesSelector: (state: RootState) => SdbProject[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return selectedRowIdsSelector(state)
        .map((selectedRowId) =>
          getProjectByIdSelector(selectedRowId.toString())(state)
        )
        .filter((project): project is SdbProject => project !== null);
    }
  );

/** Return all the group entities based on the updating items ID */
const selectedGroupEntitiesSelector: (state: RootState) => GroupTypes[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedRowIds = selectedRowIdsSelector(state);

      return selectedRowIds.reduce<GroupTypes[]>(
        (selectedEntities, selectedRow) => {
          const group = getGroupByIdSelector(selectedRow.toString())(state);
          if (group !== undefined) {
            selectedEntities.push(group);
          }
          return selectedEntities;
        },
        []
      );
    }
  );

/** Return all the point cloud entities based on the updating items ID */
const selectedPointCloudEntitiesSelector: (state: RootState) => PointCloud[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedRowIds = selectedRowIdsSelector(state);

      return selectedRowIds.reduce<PointCloud[]>(
        (selectedEntities, selectedRow) => {
          const pointCloud = getPointCloudByIdSelector(selectedRow.toString())(
            state
          );
          if (pointCloud !== undefined) {
            selectedEntities.push(pointCloud);
          }
          return selectedEntities;
        },
        []
      );
    }
  );

/** Return all the markup entities based on the updating items ID */
const selectedMarkupEntitiesSelector: (state: RootState) => Markup[] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const selectedRowIds = selectedRowIdsSelector(state);

      return selectedRowIds.reduce<Markup[]>(
        (selectedEntities, selectedRow) => {
          const markup = getMarkupByIdSelector(selectedRow.toString())(state);
          if (markup !== undefined) {
            selectedEntities.push(markup);
          }
          return selectedEntities;
        },
        []
      );
    }
  );

/** Returns the updating item based on the provided ID */
export function updatingItemByIdSelector(
  id: EntityId
): (state: RootState) => FetchingItem | null {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return TableAdapter.getSelectors().selectById(state.table, id) ?? null;
    }
  );
}

/** Returns whether any bulk action is in the provided status or not */
export function isUpdatingItemsInStatusStageSelector(
  status: FetchingItem["status"]
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const updatingItems = updatingItemsSelector(state);
      return updatingItems.some((item) => item.status === status);
    }
  );
}

/** Returns whether any bulk action is still in progress or not */
export const isBulkActionProcessingSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        isUpdatingItemsInStatusStageSelector("updating")(state) ||
        isUpdatingItemsInStatusStageSelector("in-queue")(state)
      );
    }
  );

/** Whether to show the success dialog or not */
export const shouldShowSuccessDialogSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return (
        state.table.bulkActionResults.numberOfErrors === 0 &&
        state.table.bulkActionResults.numberOfSuccess > 0
      );
    }
  );

/** Whether to show the failed dialog or not */
export const shouldShowFailedDialogSelector: (state: RootState) => boolean =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.table.bulkActionResults.numberOfErrors > 0;
    }
  );

/** Whether any bulk action results are available or not */
export const isBulkActionsResultsAvailableSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return (
      state.table.bulkActionResults.numberOfSuccess > 0 ||
      state.table.bulkActionResults.numberOfErrors > 0
    );
  }
);

/** Allowed bulk actions in a session */
export const allowedBulkButtonsInSessionSelector: (
  state: RootState
) => BulkName[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.table.allowedBulkButtonsInSession;
  }
);

/** Returns the filter model for specific table */
export function filterModelForTableSelector(
  tableType: TableType | null
): (state: RootState) => FaroFilterModel {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (tableType === null) {
        return {};
      }

      return {
        ...(state.table.filters?.[tableType] ?? {}),
      };
    }
  );
}

/** Returns the filter model for specific table */
export function filtersForSpecificColumnSelector(
  filteredColumn: FilterColumnNames
): (state: RootState) => FilterOption[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const tableType = state.table.tableType;
      const filterModel = filterModelForTableSelector(tableType)(state);

      return filterModel?.[filteredColumn] ?? [];
    }
  );
}

/** Returns Whether any filter is applied to the table or not */
export const isAnyFilterAppliedInCurrentTableSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    const tableType = state.table.tableType;
    const filterModel = filterModelForTableSelector(tableType)(state);
    if (Object.keys(filterModel).length === 0 && !state.table.quickFilter) {
      return false;
    }
    return true;
  }
);

/** Return the quick filter text */
export const quickFilterSelector: (state: RootState) => string = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.table.quickFilter;
  }
);
