import { DataTestIdProps } from "@custom-types/ui-types";
import {
  // eslint-disable-next-line no-restricted-imports -- The only place needed to create FaroIconButton
  IconButton,
  IconButtonProps,
  LinkProps,
  SvgIcon,
  SvgIconProps,
} from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import {
  hoverDisabledStyle,
  hoverStyle,
  clickDisabledStyle,
  clickStyle,
} from "@styles/common-styles";
import { getSvgColoredIconCss } from "@utils/ui-utils";
import React, { ElementType, MouseEventHandler, useMemo } from "react";
import { Link } from "react-router-dom";

export interface FaroIconButtonProps extends DataTestIdProps {
  /**
   * Defines a component to show as an icon.
   * E.g.
   * import { ReactComponent as FeedbackIcon } from "@assets/icons/Feedback.svg";
   * component={FeedbackIcon}
   */
  component: ElementType;

  /**
   * Optional id to be added to the component as a regular HTML id.
   */
  id?: string;

  /**
   * Triggered when the users clicks on the button.
   */
  onClick?: MouseEventHandler<HTMLButtonElement>;

  /** Callback event after clicking on the middle button */
  onAuxClick?: MouseEventHandler<HTMLButtonElement>;

  /** Callback event after clicking the right button to open the context menu */
  onContextMenu?: MouseEventHandler<HTMLButtonElement>;

  /**
   * Optional color to colorize the svg icon.
   * Default sphereColors.gray850.
   */
  color?: string;

  /**
   * Optional color for the icon when it is disabled.
   * Default sphereColors.gray400.
   */
  disabledColor?: string;

  /**
   * Optional props to be forwarded to the IconButton element.
   */
  iconButtonProps?: IconButtonProps;

  /**
   * Optional props to be forwarded to the SvgIcon element.
   */
  iconProps?: SvgIconProps;

  /**
   * Optional size in pixels for the outer button.
   * Default "36px".
   */
  buttonSize?: string | number;

  /**
   * Optional size in pixels for the icon.
   * Default "24px".
   */
  iconSize?: string | number;

  /**
   * Optional flag to disable the button.
   * Default false.
   */
  isDisabled?: boolean;

  /**
   * Optional flag to hide the button.
   * Default false.
   */
  isHidden?: boolean;

  /** Optional href to be used when the button is a link. */
  href?: LinkProps["href"];

  /** Optional target to be used when the button is a link. */
  target?: LinkProps["target"];

  /** Optional rel to be used when the button is a link. */
  rel?: LinkProps["rel"];
}

/**
 * Renders an MUI IconButton component that has an SvgIcon component inside.
 * This component is preferred over using IconButton because it has some
 * default properties and animations like color to blue the icon on hover.
 */
export function FaroIconButton({
  iconButtonProps,
  id,
  onClick,
  onAuxClick,
  onContextMenu,
  component,
  iconProps,
  color = sphereColors.gray800,
  disabledColor = sphereColors.gray400,
  buttonSize = "36px",
  iconSize = "24px",
  isDisabled,
  isHidden = false,
  href,
  target,
  rel,
  dataTestId,
}: FaroIconButtonProps): JSX.Element {
  /** Props to be added to the icon button when used as a link */
  const linkProps: IconButtonProps & LinkProps = useMemo(() => {
    if (!href) {
      return {};
    }
    return {
      // Different than menu item component, for an icon button LinkComponent is needed.
      LinkComponent: Link,
      // It seems we need to provide "to" for a link component.
      to: href,
      // We provide both just in case
      href,
      target,
      rel,
    };
  }, [href, target, rel]);

  if (isHidden) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- We need to return something
    return <></>;
  }

  /**
   * Triggered when the user clicks on the button. and calls the clickCallback if it is not disabled.
   */
  function onInnerClick(
    event: React.MouseEvent,
    clickCallback?: MouseEventHandler
  ): void {
    if (isDisabled || !clickCallback) {
      return;
    }
    clickCallback(event);
  }

  return (
    <IconButton
      id={id}
      // Do not use the disabled property to have the disabled effect. When disabled MUI used pointer-events=none,
      // which has some side effects like the tooltips cannot be shown and we can't catch events when clicked.
      // Instead we use a custom style for this and we don't call onClick if isDisabled = true.
      disabled={false}
      disableRipple={true}
      color="inherit"
      data-testid={dataTestId}
      onClick={(event) => onInnerClick(event, onClick)}
      onAuxClick={(event) => onInnerClick(event, onAuxClick)}
      onContextMenu={(event) => onInnerClick(event, onContextMenu)}
      {...iconButtonProps}
      sx={{
        height: buttonSize,
        width: buttonSize,
        "&:hover": {
          ...(isDisabled ? hoverDisabledStyle : hoverStyle),
        },
        "&:active": {
          ...(isDisabled ? clickDisabledStyle : clickStyle),
        },
        ...iconButtonProps?.sx,
      }}
      {...linkProps}
    >
      <SvgIcon
        inheritViewBox
        component={component}
        sx={{
          ...getSvgColoredIconCss(isDisabled ? disabledColor : color),
          height: iconSize,
          width: iconSize,
        }}
        {...iconProps}
      />
    </IconButton>
  );
}
