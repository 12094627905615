import { Box, Typography } from "@mui/material";
import { DropHandler } from "@components/common/sphere-dropzone/drop-handler";
import { ReactSetStateFunction } from "@custom-types/types";
import { useFileUpload } from "@hooks/use-file-upload";
import { ChangeEvent, DragEvent, useCallback, useState } from "react";
import { DropzoneElements } from "@components/common/sphere-dropzone/dropzone-elements";
import { sphereColors } from "@styles/common-colors";
import { useToast } from "@hooks/use-toast";
import { FILE_SIZE_MULTIPLIER } from "@utils/file-utils";
import { isNumber } from "lodash";
import {
  FileUploadTaskContext,
  UploadedFileResponse,
  UploadFileParams,
  UploadMultipleFilesParams,
} from "@custom-types/file-upload-types";
import { isValidFile } from "@hooks/file-upload-utils";

interface Props {
  /** Avatar component of user. If provided existingImageUrl will be ignored */
  avatar?: JSX.Element;

  /** Existing url of an image to display inside the dropzone */
  existingImageUrl?: string;

  /** True if logo uploading is in progress */
  isLoading: boolean;

  /** Callback function to trigger to set the loading state */
  setIsLoading: ReactSetStateFunction<boolean>;

  /** Callback function on file upload complete */
  onUploadComplete(
    uploadedResponse: string | UploadedFileResponse[],
    context: FileUploadTaskContext
  ): void;

  /** Callback function which triggers on delete button click */
  onDeleteButtonClick?(): void;

  /** Text to show in delete icon tooltip */
  deleteIconTooltipText?: string;

  /** Give a title for the drag and drop zone */
  titleDragAndDrop?: string;

  /** Give the button upload file */
  hasUploadFileButton?: boolean;

  /** Whether the supported format should show in the dropzone */
  shouldShowSupportedFormats?: boolean;

  /** Whether the size limit should show in the dropzone */
  shouldShowSizeLimit?: boolean;

  /** Whether the dropzone should handle multiple file or not */
  shouldAllowMultiUpload?: boolean;

  /** List of file extensions that are allowed in the dropzone */
  allowedExtensions?: string[];

  /** First part of instruction, can be different based on the usage of the dropzone */
  instruction?: string;

  /** Maximum file size the dropzone should allow to upload */
  maxFileSize?: number;

  /** Additional information of the file upload task */
  context: FileUploadTaskContext;

  /** Callback function when new files are selected  */
  onSelectFiles?(files: FileList, confirmCallback: () => void): void;
}

/** Contains all the sorted default file extensions that the default dropzone support  */
const DEFAULT_FILE_EXTENSIONS_WHITE_LIST = ["gif", "jpeg", "jpg", "png", "svg"];

/** Default maximum MB size of the file to upload */
const DEFAULT_MAX_UPLOAD_SIZE_MB: number = 10;

/** Maximum MB size of the file to use normal upload, higher than that will use chunk upload */
const MAX_FILE_SIZE_FOR_NORMAL_UPLOAD: number = 5;

/**
 * Renders the dropzone area to upload a file
 */
export function SphereDropzone({
  avatar,
  existingImageUrl,
  isLoading,
  setIsLoading,
  onUploadComplete,
  onDeleteButtonClick,
  deleteIconTooltipText,
  titleDragAndDrop,
  hasUploadFileButton,
  shouldShowSupportedFormats,
  shouldShowSizeLimit,
  allowedExtensions = DEFAULT_FILE_EXTENSIONS_WHITE_LIST,
  instruction,
  maxFileSize = DEFAULT_MAX_UPLOAD_SIZE_MB,
  shouldAllowMultiUpload = false,
  context,
  onSelectFiles,
}: Props): JSX.Element {
  const {
    uploadSingleFile,
    uploadFileWithChunks,
    uploadMultipleFiles,
    validateAndAddFailedTask,
  } = useFileUpload();
  const { showToast } = useToast();

  const [fileInputEl, setFileInputEl] = useState<HTMLElement | null>(null);
  const [isFileExplorerOpen, setIsFileExplorerOpen] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const openFileExplorer = useCallback(() => {
    fileInputEl?.click();
    setIsFileExplorerOpen(true);
  }, [fileInputEl]);

  /** Selects files from file explorer after click on the dropzone */
  function selectFileFromDialog(event: ChangeEvent<HTMLInputElement>): void {
    setIsFileExplorerOpen(false);

    const files = event.target.files;
    handleFileSelection(files);
  }

  /** Selects files from drag and drop */
  function selectFileFromDrop(event: DragEvent<HTMLElement>): void {
    event.preventDefault();

    const files = event.dataTransfer?.files;
    handleFileSelection(files);
  }

  /** Handle files after selection or drop */
  function handleFileSelection(files: FileList | null): void {
    // Early return if there is no file dropped
    if (!files || files.length === 0) {
      return;
    }

    // Waiting for confirmation to start uploading if onSelectFiles callback is provided
    if (onSelectFiles) {
      onSelectFiles(files, () => {
        if (shouldAllowMultiUpload) {
          initiateMultipleFileUpload(files);
        } else {
          initiateFileUpload(files[0]);
        }
      });

      return;
    }

    // Proceed uploading if there is no confirmation required
    shouldAllowMultiUpload
      ? initiateMultipleFileUpload(files)
      : initiateFileUpload(files[0]);
  }

  /** Triggers when file upload starts */
  function onUploadStart(): void {
    // Progress loader should not visible to make the dropzone available for further upload
    if (!shouldAllowMultiUpload) {
      setIsLoading(true);
    }
  }

  /** Triggers to set the file upload progress value */
  function onUploadProgress(progress: ProgressEvent | number): void {
    const uploadProgress = isNumber(progress)
      ? progress
      : (progress.loaded / progress.total) * 100;

    setUploadProgress(uploadProgress);
  }

  /** Triggers when file is selected or dropped */
  async function initiateFileUpload(file: File | undefined): Promise<void> {
    // Early return if file is not available
    if (!file) {
      return;
    }

    const validatedFile = isValidFile({ file, allowedExtensions, maxFileSize });
    if (!validatedFile.isValid) {
      showToast({
        message: validatedFile.message,
        description: validatedFile.description,
        type: "error",
      });

      return;
    }

    // Need to reset the progress to start from 0 next time
    setUploadProgress(0);

    const uploadParams: UploadFileParams = {
      file,
      onUploadStart,
      onUploadProgress,
      onUploadComplete,
      context,
    };

    const shouldUseChunkUpload =
      file.size >
      MAX_FILE_SIZE_FOR_NORMAL_UPLOAD *
        FILE_SIZE_MULTIPLIER *
        FILE_SIZE_MULTIPLIER;

    // Use chunk upload if file size is bigger than MAX_FILE_SIZE_FOR_NORMAL_UPLOAD
    const isUploaded = shouldUseChunkUpload
      ? await uploadFileWithChunks(uploadParams)
      : await uploadSingleFile(uploadParams);

    // Hiding the loader spinner if upload is failed
    if (!isUploaded) {
      setIsLoading(false);
    }
  }

  async function initiateMultipleFileUpload(files: FileList): Promise<void> {
    // Early return if projectId is not provided
    // Only for type checking
    if (!context.projectId) {
      return;
    }

    const uploadableFiles = Array.from(files).filter((file) =>
      validateAndAddFailedTask({
        file,
        allowedExtensions,
        maxFileSize,
        context,
      })
    );

    // Return if there is no uploadable file
    if (!uploadableFiles.length) {
      return;
    }

    const uploadParams: UploadMultipleFilesParams = {
      files: uploadableFiles,
      onUploadStart,
      onUploadProgress,
      onUploadComplete,
      context,
    };

    await uploadMultipleFiles(uploadParams);
  }

  return (
    <Box component="div">
      {(titleDragAndDrop || hasUploadFileButton) && (
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          {titleDragAndDrop && (
            <Typography
              sx={{
                fontWeight: 600,
                color: sphereColors.gray800,
                mb: "10px",
                fontSize: "14px",
              }}
            >
              {titleDragAndDrop}
            </Typography>
          )}

          {hasUploadFileButton && (
            <Typography
              sx={{
                fontWeight: 600,
                color: sphereColors.blue500,
                mb: "10px",
                fontSize: "12px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={openFileExplorer}
            >
              Upload file
            </Typography>
          )}
        </Box>
      )}
      <DropHandler
        onDrop={selectFileFromDrop}
        canBeDropped={!isFileExplorerOpen}
        onClick={openFileExplorer}
        isLoading={isLoading}
        shouldAllowMultiUpload={shouldAllowMultiUpload}
      >
        <DropzoneElements
          avatar={avatar}
          isLoading={isLoading}
          uploadProgress={uploadProgress}
          existingImageUrl={existingImageUrl}
          setFileInputEl={setFileInputEl}
          selectFileFromDialog={selectFileFromDialog}
          onDeleteButtonClick={onDeleteButtonClick}
          deleteIconTooltipText={deleteIconTooltipText}
          shouldShowSupportedFormats={shouldShowSupportedFormats}
          shouldShowSizeLimit={shouldShowSizeLimit}
          allowedExtensions={allowedExtensions}
          instruction={instruction}
          maxFileSize={maxFileSize}
          shouldAllowMultiUpload={shouldAllowMultiUpload}
        />
      </DropHandler>
    </Box>
  );
}
