import { StringUtils } from "@stellar/web-core";

/**
 * Whether current password string is valid.
 */
export function isValidCurrentPassword(password: string): boolean {
  return password.length > 0;
}

/**
 * Whether repeat new password string is valid.
 */
export function isValidRepeatNewPassword(
  repeatPassword: string,
  newPassword: string
): boolean {
  // Return true if there is nothing to compare the repeat password against.
  if (!newPassword) {
    return true;
  }

  return repeatPassword === newPassword;
}

/** State of each validation rule error  */
export interface PasswordErrors {
  /** Whether there is an error due to invalid password length */
  isMinCharLengthError: boolean;

  /** Whether there is an error due to invalid uppercase rule */
  isUppercaseLetterError: boolean;

  /** Whether there is an error due to invalid lowercase rule */
  isLowercaseLetterError: boolean;

  /** Whether there is an error due to invalid number rule */
  isNumberError: boolean;

  /** Whether there is an error due to invalid special chars rule */
  isSpecialCharError: boolean;

  /** Whether there is an error due to invalid leading and trailing spaces rule */
  isLeadingOrTrailingSpaceError: boolean;

  /** Whether there is an error due to invalid email rule */
  isContainsEmailError: boolean;
}

/** Interface for the response of the `isValidNewPassword` function */
export interface IsValidNewPassword {
  /** Whether the password passed the validation rules */
  isValid: boolean;

  /** State of each validation rule error */
  errors: PasswordErrors;
}

const DEFAULT_PASSWORD_ERRORS: PasswordErrors = {
  isMinCharLengthError: false,
  isUppercaseLetterError: false,
  isLowercaseLetterError: false,
  isNumberError: false,
  isSpecialCharError: false,
  isLeadingOrTrailingSpaceError: false,
  isContainsEmailError: false,
};

const MIN_PASSWORD_LENGTH = 14;

/**
 * Whether new password string is valid.
 */
export function isValidNewPassword(password: string): IsValidNewPassword {
  const errors = DEFAULT_PASSWORD_ERRORS;

  errors.isMinCharLengthError = !StringUtils.hasMinCharLength(
    password,
    MIN_PASSWORD_LENGTH
  );
  errors.isUppercaseLetterError = !StringUtils.hasUppercaseLetter(password);
  errors.isLowercaseLetterError = !StringUtils.hasLowercaseLetter(password);
  errors.isNumberError = !StringUtils.hasNumber(password);
  errors.isSpecialCharError = !StringUtils.hasSpecialChar(password);
  errors.isLeadingOrTrailingSpaceError =
    !StringUtils.hasNoLeadingOrTrailingSpace(password);
  errors.isContainsEmailError = StringUtils.containsEmail(password);

  const isValid =
    !errors.isMinCharLengthError &&
    !errors.isUppercaseLetterError &&
    !errors.isLowercaseLetterError &&
    !errors.isNumberError &&
    !errors.isSpecialCharError &&
    !errors.isLeadingOrTrailingSpaceError &&
    !errors.isContainsEmailError;

  return { isValid, errors };
}
