import { Checkbox, MenuItem, Stack } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import CheckIcon from "@assets/icons/new/check_18px.svg?react";
import { FilterOption } from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { withEllipsis } from "@styles/common-styles";
import { SphereTooltip } from "@components/common/sphere-tooltip";

interface Props {
  /** Contains properties of dropdown item */
  filterItem: FilterOption;

  /** Whether the item is selected or not */
  isItemSelected: boolean;

  /** Callback function when an item is clicked */
  onItemClicked(newFilterItem: FilterOption): void;

  /** Whether to show a checkbox next to each item or not */
  shouldShowCheckbox: boolean;

  /** Whether to show a check icon on right side of the selected item or not */
  shouldShowCheckIconOnRight: boolean;

  /** The size of the dropdown */
  size?: "small" | "large";
}

/** Renders items inside a filter chip */
export function FilterChipDropdownItem({
  filterItem,
  isItemSelected,
  onItemClicked,
  shouldShowCheckbox,
  shouldShowCheckIconOnRight,
  size = "small",
}: Props): JSX.Element {
  return (
    <MenuItem
      disableRipple
      disableTouchRipple
      key={filterItem.id}
      value={
        filterItem.filterValue ? filterItem.filterValue.toString() : undefined
      }
      selected={isItemSelected}
      onClick={() => onItemClicked(filterItem)}
      sx={{
        height: "40px",
        mx: "10px",
      }}
    >
      {shouldShowCheckbox && (
        <Checkbox
          size="small"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          checked={isItemSelected}
          sx={{
            pl: "0px",
          }}
        />
      )}
      <Stack
        sx={{
          alignItems: "center",
          gap: "5px",
          flexDirection: "row",
          color: isItemSelected ? sphereColors.blue500 : "inherit",
        }}
      >
        {filterItem.icon}
        {/* The width of this Box should be equal to the Menu DropdownFilterChip's width minus 60px */}
        <SphereTooltip
          title={filterItem.label}
          boxProps={{
            sx: {
              maxWidth: size === "small" ? "120px" : "240px",
              ...withEllipsis,
            },
          }}
        >
          <var>{filterItem.label}</var>
        </SphereTooltip>

        {shouldShowCheckIconOnRight && isItemSelected && (
          <CheckIcon
            style={{
              fontSize: "13px",
              color: sphereColors.blue500,
              fontWeight: "bold",
            }}
          />
        )}
      </Stack>
    </MenuItem>
  );
}
