import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

/**
 * Returns whether the logged in user login method is FARO-SPHERE
 *
 * @param loggedInUser Logged in user
 */
export function isLoginProviderFaro(
  loggedInUser: SphereDashboardAPITypes.IGetLoggedInUserResponse | null
): boolean {
  if (!loggedInUser) {
    return false;
  }

  return (
    loggedInUser.data.providerId === CoreAPITypes.EUserJsonProviderId.faroSphere
  );
}
