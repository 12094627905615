import { useMediaQuery, useTheme } from "@mui/material";

interface MediaQueryList {
  /** Whether the screen is extra large or larger */
  isScreenXlOrLarger: boolean;

  /** Whether the screen is medium or larger */
  isScreenMdAndLarger: boolean;

  /** Whether the screen is extra small or small */
  isScreenXsAndSmall: boolean;

  /** Whether the screen is large */
  isLarge: boolean;

  /** Whether the screen is large and larger */
  isScreenLgAndLarger: boolean;

  /** Whether the screen is medium */
  isMedium: boolean;

  /** Whether the screen is small or larger */
  isScreenSmOrLarger: boolean;

  /** Whether the screen is small */
  isSmall: boolean;

  /** Whether the screen is small or smaller */
  isScreenSmOrSmaller: boolean;

  /** Whether the screen is extra small */
  isExtraSmall: boolean;
}

// TODO: Fix the namings to use OR instead of AND: https://faro01.atlassian.net/browse/ST-1348
/**
 * List of important information to get about the users display
 * values: {"xs": 0, "sm": 600, "md": 900, "lg": 1200, "xl": 1536}
 */
export function useMediaQueryList(): MediaQueryList {
  const theme = useTheme();
  const isScreenXlOrLarger = useMediaQuery(theme.breakpoints.up("xl"));
  const isScreenLgAndLarger = useMediaQuery(theme.breakpoints.up("lg"));
  const isLarge = useMediaQuery(theme.breakpoints.only("lg"));
  const isScreenMdAndLarger = useMediaQuery(theme.breakpoints.up("md"));
  const isMedium = useMediaQuery(theme.breakpoints.only("md"));
  const isScreenSmOrLarger = useMediaQuery(theme.breakpoints.up("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.only("sm"));
  const isScreenXsAndSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isScreenSmOrSmaller = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmall = useMediaQuery(theme.breakpoints.only("xs"));

  return {
    isScreenXlOrLarger,
    isScreenLgAndLarger,
    isLarge,
    isScreenMdAndLarger,
    isMedium,
    isScreenSmOrLarger,
    isSmall,
    isScreenXsAndSmall,
    isScreenSmOrSmaller,
    isExtraSmall,
  };
}
