import { Box, Grid, Tab, Tabs } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import CameraIcon from "@assets/icons/new/camera_24px.svg?react";
import { PropsWithChildren, useMemo, useState } from "react";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import {
  TAB_WITH_NO_UNDERLINE,
  DEFAULT_TAB_UNDERLINE,
} from "@styles/common-styles";
import { ProjectThumbnailGallery } from "@pages/project-details/project-overview/project-thumbnail/project-thumbnail-gallery";
import { useAppDispatch } from "@store/store-helper";
import { ChangeProjectDetailsEvents } from "@utils/track-event/track-event-list";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useToast } from "@hooks/use-toast";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { SphereDropzone } from "@components/common/sphere-dropzone/sphere-dropzone";
import { useFileUpload } from "@hooks/use-file-upload";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { isString } from "lodash";
import {
  FileUploadTaskContext,
  UploadElementType,
} from "@custom-types/file-upload-types";

enum ChangeThumbnailTab {
  /** Tab from where an image can be selected from the existing image gallery  */
  gallery = "Gallery",

  /** Tab from where an image can be uploaded from computer  */
  computer = "From computer",
}

interface Props extends BaseProjectIdProps {
  /** Link to the existing project thumbnail */
  existingThumbnailUrl?: string;

  /** True if the current user is allowed to change the thumbnail image */
  isAllowedToChange: boolean;
}

/** Renders a dialog to change the thumbnail of a project */
export function ChangeThumbnailDialog({
  children,
  projectId,
  existingThumbnailUrl,
  isAllowedToChange,
}: PropsWithChildren<Props>): JSX.Element {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const { uploadSingleFile } = useFileUpload();
  const { trackEvent } = useTrackEvent();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [selectedThumbnail, setSelectedThumbnail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<ChangeThumbnailTab>(
    ChangeThumbnailTab.computer
  );

  const selectedTabContent = useMemo(() => {
    switch (selectedTab) {
      case ChangeThumbnailTab.gallery:
        return (
          <ProjectThumbnailGallery
            selectedThumbnail={selectedThumbnail}
            onSelectImage={onSelectImageFromGallery}
          />
        );

      case ChangeThumbnailTab.computer:
        return (
          <SphereDropzone
            existingImageUrl={existingThumbnailUrl}
            isLoading={isUploading}
            setIsLoading={setIsUploading}
            onUploadComplete={(uploadedResponse, context) => {
              if (isString(uploadedResponse)) {
                updateProjectThumbnail(
                  uploadedResponse,
                  context,
                  "From Computer"
                );
              }
            }}
            context={{
              uploadElementType: UploadElementType.project,
              projectId,
            }}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- All shouldn't be in the dependency list
  }, [selectedTab, selectedThumbnail, existingThumbnailUrl, isUploading]);

  if (!isAllowedToChange) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to return JSX.Element
    return <>{children}</>;
  }

  /** Triggers when an image is selected from gallery tab */
  function onSelectImageFromGallery(imageUrl: string): void {
    setIsConfirmDisabled(false);
    setSelectedThumbnail(imageUrl);
  }

  /**
   * Update project thumbnail from gallery or by uploading from computer
   *
   * @param thumbnailUrl The url of the new image
   * @param context Additional information of the file upload task
   * @param location From where the change is triggered
   */
  async function updateProjectThumbnail(
    thumbnailUrl: string,
    context: FileUploadTaskContext,
    location: string
  ): Promise<void> {
    setIsLoading(true);

    trackEvent({
      name: ChangeProjectDetailsEvents.changeProjectThumbnail,
      props: { location, thumbnailUrl: selectedThumbnail },
    });

    try {
      await dispatch(
        updateProjectDetails({
          coreApiClient,
          projectId: context.projectId,
          payload: { thumbnailUrl },
        })
      ).unwrap();

      showToast({
        message: "Project thumbnail image changed.",
        type: "success",
      });
    } catch (error) {
      // Handle error directly here in order to show specific info about the field that failed: thumbnailUrl
      // Otherwise the error will be handled by the error slice, but that will show a generic message without details
      handleErrorWithToast({
        id: `updateProjectDetails-thumbnail-${Date.now().toString()}`,
        title: "Could not change project thumbnail image",
        error,
      });
    } finally {
      setIsLoading(false);
      setIsConfirmDisabled(true);
      setIsDialogOpen(false);
      setIsUploading(false);
    }
  }

  /** Uploads a file which is selected from gallery */
  async function uploadSelectedThumbnail(): Promise<void> {
    // Load the file as File object
    const fileResponse = await fetch(selectedThumbnail).then((res) =>
      res.arrayBuffer()
    );
    const fileName = selectedThumbnail.split("/").pop() ?? "thumbnail.svg";
    const file = new File([fileResponse], fileName, { type: "image/svg+xml" });

    // Upload the gallery file
    await uploadSingleFile({
      file: file,
      onUploadStart: () => setIsLoading(true),
      onUploadProgress: () => {
        // Not showing progress here
      },
      onUploadComplete: (uploadedResponse, context) =>
        updateProjectThumbnail(uploadedResponse, context, "Thumbnail Gallery"),
      context: {
        uploadElementType: UploadElementType.project,
        projectId,
      },
    });
  }

  return (
    <>
      <Box
        onClick={() => setIsDialogOpen(true)}
        sx={{
          position: "relative",
          cursor: "pointer",
          width: "100%",
          [`&:hover .${SHOW_ON_HOVER_CLASS}`]: {
            // Show the element with specific display property to place it in the middle
            display: "grid",
          },
        }}
      >
        {children}

        <Box
          component="span"
          className={SHOW_ON_HOVER_CLASS}
          sx={{
            background: sphereColors.pureWhite,
            opacity: 0.6,
            position: "absolute",
            left: "calc(50% - 50px)",
            top: "calc(50% - 50px)",
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            display: "none",
            placeContent: "center",
          }}
        >
          <CameraIcon
            style={{
              fill: sphereColors.gray800,
              height: "35px",
              width: "45px",
            }}
          />
        </Box>
      </Box>

      <FaroDialog
        title="Change thumbnail"
        open={isDialogOpen}
        confirmText={isLoading ? "Applying Image" : "Apply Image"}
        onConfirm={uploadSelectedThumbnail}
        isConfirmDisabled={isConfirmDisabled}
        onClose={() => setIsDialogOpen(false)}
        isConfirmLoading={isLoading}
        shouldHideActions={selectedTab === ChangeThumbnailTab.computer}
      >
        <Grid
          maxWidth="100%"
          width="70vw"
          height="auto"
          // This margin is applied to resolve jumping effect of modal
          // As From computer tab has no action buttons at the bottom
          marginBottom={
            selectedTab === ChangeThumbnailTab.computer ? "30px" : "0px"
          }
        >
          <Tabs
            value={selectedTab}
            scrollButtons
            TabIndicatorProps={{
              sx: {
                backgroundColor: sphereColors.blue500,
                height: "3px",
              },
            }}
            sx={{
              boxShadow: {
                xs: TAB_WITH_NO_UNDERLINE,
                md: DEFAULT_TAB_UNDERLINE,
              },
            }}
          >
            {Object.values(ChangeThumbnailTab).map((tab) => (
              <Tab
                disableRipple
                key={tab}
                label={tab}
                value={tab}
                onClick={() => setSelectedTab(tab)}
                sx={{
                  padding: 0,
                  fontSize: "14px",
                  letterSpacing: "-0.2px",
                  marginRight: "20px",
                  textTransform: "none",
                  minWidth: "30px",
                  fontWeight: selectedTab === tab ? "bold" : "lighter",
                  "&.MuiTab-root": {
                    color:
                      selectedTab === tab
                        ? sphereColors.blue500
                        : sphereColors.gray800,
                  },
                  "&:hover": {
                    color: sphereColors.blue500,
                    borderBottom: `1px solid ${sphereColors.blue500}`,
                    // Add a top padding to avoid the tab content from jumping
                    paddingTop: "1px",
                  },
                }}
              />
            ))}
          </Tabs>

          {selectedTabContent}
        </Grid>
      </FaroDialog>
    </>
  );
}
