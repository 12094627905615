import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { ProjectArchivingState } from "@custom-types/project-types";
import { CompanyMemberTypes, MemberTypes } from "@custom-types/member-types";
import { GroupTypes } from "@custom-types/group-types";
import { ApiError } from "@custom-types/types";
import { ElementType } from "react";
import {
  ArrayFilterOption,
  BetweenFilterOption,
  EmptyFilterOption,
  FilterOption,
  NumberFilterOption,
  StringFilterOption,
} from "@components/common/faro-table/faro-table-filter/faro-table-filter-types";
import { LanguageCodes, LanguageObject } from "@faro-lotv/flat-ui";

/**
 * Determines whether the role is related to company or not
 */
export function isCompanyRole(
  role: CoreAPITypes.EUserCompanyRole | CoreAPITypes.EUserProjectRole
): role is CoreAPITypes.EUserCompanyRole {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- allows EUserProjectRole inputs
  return Object.values(CoreAPITypes.EUserCompanyRole).includes(role as any);
}

/**
 * Determines whether the role is related to project or not
 */
export function isProjectRole(
  role: CoreAPITypes.EUserCompanyRole | CoreAPITypes.EUserProjectRole
): role is CoreAPITypes.EUserProjectRole {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- allows EUserProjectRole inputs
  return Object.values(CoreAPITypes.EUserProjectRole).includes(role as any);
}

/**
 * Determines whether the role is related to group or not
 */
export function isGroupRole(
  role:
    | CoreAPITypes.EUserCompanyRole
    | CoreAPITypes.EUserProjectRole
    | SphereDashboardAPITypes.IAssignmentGroupRole
): role is SphereDashboardAPITypes.IAssignmentGroupRole {
  return [
    CoreAPITypes.EUserCompanyRole.companyManager,
    CoreAPITypes.EUserCompanyRole.projectManager,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- allows role inputs
  ].includes(role as any);
}

/**
 * Determines whether the member is for projects or not
 */
export function isProjectMember(
  member: MemberTypes
): member is SphereDashboardAPITypes.IProjectMemberBase {
  return !isCompanyRole(member.role);
}

/**
 * Determine whether the type of member is from company base (when requesting all members for a company) or not
 */
export function isCompanyMemberBase(
  member: MemberTypes
): member is SphereDashboardAPITypes.ICompanyMemberBase {
  return (
    "role" in member &&
    isCompanyRole(member.role) &&
    !("projectRoles" in member)
  );
}

/**
 * Determine whether the type of member is from company with details or not
 */
export function isCompanyMemberDetails(
  member: MemberTypes
): member is SphereDashboardAPITypes.ICompanyMemberDetails {
  return (
    "role" in member && isCompanyRole(member.role) && "projectRoles" in member
  );
}

/**
 * Determine whether the type of member is from company or not
 */
export function isCompanyMember(
  member: MemberTypes
): member is CompanyMemberTypes {
  return isCompanyMemberBase(member) || isCompanyMemberDetails(member);
}

/**
 * Determine whether the type is for details of a group
 */
export function isGroupDetails(
  group: GroupTypes
): group is SphereDashboardAPITypes.IGroupDetails {
  // Use the unique fields "members" and "projects" from the IGroupDetails for the type guard check.
  return "members" in group && "projects" in group;
}

/**
 * Determines whether the member type is from group details
 */
export function isGroupDetailsMember(
  member: MemberTypes
): member is SphereDashboardAPITypes.IGroupMemberDetails {
  return "dateJoined" in member;
}

/** Whether the error is in the type of ApiError or not */
export function isApiError(error: unknown): error is ApiError {
  if (error && typeof error === "object" && "message" in error) {
    return true;
  }
  return false;
}

/** Whether the project status is of type ProjectArchivingState */
export function isProjectArchivingState(
  projectStatus: ProjectArchivingState | undefined
): projectStatus is ProjectArchivingState {
  return (
    projectStatus !== undefined &&
    Object.values(ProjectArchivingState).includes(projectStatus)
  );
}

/** Determine whether the provided image src is png or svg */
export function isSrcOfTypeString(src: string | ElementType): src is string {
  return typeof src === "string";
}

/** Whether the provided status is one of the common ones we introduced a type for  */
export function isMarkupStatusKnown(
  status: string | null | undefined
): status is CoreAPITypes.EIssueStatus {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- allows EUserProjectRole inputs
  return Object.values(CoreAPITypes.EIssueStatus).includes(status as any);
}

/** Whether the provided language object from localizeJS is same as LanguageObject  */
export function isLanguageObject(
  language: LocalizeJS.Context.LanguageObject
): language is LanguageObject {
  return (
    "code" in language &&
    "name" in language &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.values(LanguageCodes).includes(language.code as any)
  );
}

/** Whether the provided filterOption is in type of ArrayFilterOption */
export function isArrayFilterOption(
  filterOption: FilterOption
): filterOption is ArrayFilterOption {
  return filterOption.operator === "includes";
}

/** Whether the provided filterOption is in type of BetweenFilterOption */
export function isBetweenFilterOption(
  filterOption: FilterOption
): filterOption is BetweenFilterOption {
  return filterOption.operator === "between";
}

/** Whether the provided filterOption is in type of StringFilterOption */
export function isStringFilterOption(
  filterOption: FilterOption
): filterOption is StringFilterOption {
  return (
    typeof filterOption.filterValue === "string" &&
    filterOption.operator === "equal"
  );
}

/** Whether the provided filterOption is in type of EmptyFilterOption */
export function isEmptyFilterOption(
  filterOption: FilterOption
): filterOption is EmptyFilterOption {
  return (
    filterOption.filterValue === undefined && filterOption.operator === "equal"
  );
}

/** Whether the provided filterOption is in type of NumberFilterOption */
export function isNumberFilterOption(
  filterOption: FilterOption
): filterOption is NumberFilterOption {
  return (
    !isArrayFilterOption(filterOption) &&
    !isBetweenFilterOption(filterOption) &&
    !isStringFilterOption(filterOption) &&
    !isEmptyFilterOption(filterOption)
  );
}

/** Whether the value is in type of string[] or not */
export function isStringArray(value: unknown): value is string[] {
  return (
    Array.isArray(value) &&
    // value.every returns true for an empty array. So it needs to be checked separately
    value.length > 0 &&
    value.every((item) => typeof item === "string")
  );
}
