import { Chip, ChipOwnProps, SxProps } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { ReactElement } from "react";

interface Props {
  /** Text to display inside the chip */
  label: string;

  /** Label color */
  color: string;

  /** Chip background color */
  backgroundColor: string;

  /** Tooltip text. If not passed it won't show a tooltip */
  tooltip?: string;

  /**
   * The chip variant to use.
   *
   * @default 'filled'
   */
  variant?: ChipOwnProps["variant"];

  /** Optional icon to override the default circle icon of the chip */
  icon?: ReactElement;

  /** Optional sx properties to override the chip style. */
  styleChip?: SxProps;

  /**
   * The size variant to use.
   *
   * @default 'small'
   */
  size?: ChipOwnProps["size"];
}

/**
 * Renders a status inside a chip
 */
export function SphereStatusChip({
  label,
  color,
  backgroundColor,
  tooltip,
  variant = "filled",
  size = "small",
  icon,
  styleChip,
}: Props): JSX.Element {
  const shouldShowTooltip = typeof tooltip === "string";

  return (
    <SphereTooltip
      title={tooltip}
      enterDelay={1000}
      boxProps={{
        sx: {
          display: "inline-flex",
          cursor: "default",
        },
      }}
      shouldShowTooltip={shouldShowTooltip}
    >
      <Chip
        data-testid="sphere-status-chip"
        label={label}
        icon={
          icon ? (
            icon
          ) : (
            <CircleIcon
              sx={{
                width: "6px",
                height: "6px",
              }}
            />
          )
        }
        variant={variant}
        sx={{
          backgroundColor: backgroundColor,
          color: color,
          borderColor: color,
          fontSize: "12px",
          "& .MuiChip-icon": {
            marginLeft: "8px",
            marginRight: "0px",
            color: color,
          },
          ...styleChip,
        }}
        size={size}
      />
    </SphereTooltip>
  );
}
