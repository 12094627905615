import SearchIcon from "@assets/icons/new/search_24px.svg?react";
import { SearchTextField } from "@components/common/search-text-field";
import { useMediaQueryList } from "@hooks/use-media-query";
import {
  Box,
  ClickAwayListener,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  quickFilterSelector,
  tableTypeSelector,
} from "@store/table/table-selector";
import { setQuickFilter } from "@store/table/table-slice";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import { TableType } from "@utils/track-event/track-event-list";
import { addTransparency } from "@utils/ui-utils";
import { useEffect, useMemo, useState } from "react";

/** Handling the quick search for filtering the table */
export function FilterToolbarQuickSearch(): JSX.Element {
  const {
    isScreenXlOrLarger,
    isScreenMdAndLarger,
    isScreenSmOrLarger,
    isScreenSmOrSmaller,
  } = useMediaQueryList();
  const [shouldShowSearchBox, setShouldShowSearchBox] =
    useState<boolean>(false);
  const [placeHolder, setPlaceHolder] = useState<string>("");

  const tableType = useAppSelector(tableTypeSelector);
  const quickFilter = useAppSelector(quickFilterSelector);

  const dispatch = useAppDispatch();

  const searchWidth = useMemo(() => {
    if (isScreenXlOrLarger) {
      return "500px";
    }
    if (isScreenMdAndLarger) {
      return "300px";
    }
    if (isScreenSmOrLarger) {
      return "400px";
    }
    if (isScreenSmOrSmaller) {
      return "360px";
    }
  }, [
    isScreenMdAndLarger,
    isScreenSmOrLarger,
    isScreenSmOrSmaller,
    isScreenXlOrLarger,
  ]);

  /** Sets the placeholder based on the table type */
  useEffect(() => {
    switch (tableType) {
      case TableType.projectMarkups:
        setPlaceHolder("Search annotations");
        break;

      default:
        setPlaceHolder("Search");
        break;
    }
  }, [tableType]);

  function onClickAway(): void {
    if (!quickFilter) {
      setShouldShowSearchBox(false);
    }
  }

  function onClearClicked(): void {
    setShouldShowSearchBox(false);
    dispatch(setQuickFilter(""));
  }

  if (shouldShowSearchBox) {
    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <Box component="div">
          <SearchTextField
            placeholder={placeHolder}
            searchText={quickFilter}
            onClearClicked={onClearClicked}
            onChangeSearch={(text) => dispatch(setQuickFilter(text))}
            sx={{ width: searchWidth }}
          />
        </Box>
      </ClickAwayListener>
    );
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      onClick={() => setShouldShowSearchBox(true)}
      sx={{
        cursor: "pointer",
        p: "6px 10px",
        "&:hover": {
          backgroundColor: addTransparency({
            color: sphereColors.gray500,
            alpha: EDecimalToHex.twentySix,
          }),
          color: sphereColors.blue500,
        },
      }}
    >
      <SvgIcon
        inheritViewBox
        component={SearchIcon}
        sx={{ width: "18px", height: "18px" }}
      />
      <Typography
        sx={{
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          fontWeight: 700,
          ml: "8px",
        }}
      >
        Search
      </Typography>
    </Stack>
  );
}
